import UnicodeTrie from 'unicode-trie';

var categories = [
	"Cc",
	"Zs",
	"Po",
	"Sc",
	"Ps",
	"Pe",
	"Sm",
	"Pd",
	"Nd",
	"Lu",
	"Sk",
	"Pc",
	"Ll",
	"So",
	"Lo",
	"Pi",
	"Cf",
	"No",
	"Pf",
	"Lt",
	"Lm",
	"Mn",
	"Me",
	"Mc",
	"Nl",
	"Zl",
	"Zp",
	"Cs",
	"Co"
];
var combiningClasses = [
	"Not_Reordered",
	"Above",
	"Above_Right",
	"Below",
	"Attached_Above_Right",
	"Attached_Below",
	"Overlay",
	"Iota_Subscript",
	"Double_Below",
	"Double_Above",
	"Below_Right",
	"Above_Left",
	"CCC10",
	"CCC11",
	"CCC12",
	"CCC13",
	"CCC14",
	"CCC15",
	"CCC16",
	"CCC17",
	"CCC18",
	"CCC19",
	"CCC20",
	"CCC21",
	"CCC22",
	"CCC23",
	"CCC24",
	"CCC25",
	"CCC30",
	"CCC31",
	"CCC32",
	"CCC27",
	"CCC28",
	"CCC29",
	"CCC33",
	"CCC34",
	"CCC35",
	"CCC36",
	"Nukta",
	"Virama",
	"CCC84",
	"CCC91",
	"CCC103",
	"CCC107",
	"CCC118",
	"CCC122",
	"CCC129",
	"CCC130",
	"CCC132",
	"Attached_Above",
	"Below_Left",
	"Left",
	"Kana_Voicing",
	"CCC26",
	"Right"
];
var scripts = [
	"Common",
	"Latin",
	"Bopomofo",
	"Inherited",
	"Greek",
	"Coptic",
	"Cyrillic",
	"Armenian",
	"Hebrew",
	"Arabic",
	"Syriac",
	"Thaana",
	"Nko",
	"Samaritan",
	"Mandaic",
	"Devanagari",
	"Bengali",
	"Gurmukhi",
	"Gujarati",
	"Oriya",
	"Tamil",
	"Telugu",
	"Kannada",
	"Malayalam",
	"Sinhala",
	"Thai",
	"Lao",
	"Tibetan",
	"Myanmar",
	"Georgian",
	"Hangul",
	"Ethiopic",
	"Cherokee",
	"Canadian_Aboriginal",
	"Ogham",
	"Runic",
	"Tagalog",
	"Hanunoo",
	"Buhid",
	"Tagbanwa",
	"Khmer",
	"Mongolian",
	"Limbu",
	"Tai_Le",
	"New_Tai_Lue",
	"Buginese",
	"Tai_Tham",
	"Balinese",
	"Sundanese",
	"Batak",
	"Lepcha",
	"Ol_Chiki",
	"Braille",
	"Glagolitic",
	"Tifinagh",
	"Han",
	"Hiragana",
	"Katakana",
	"Yi",
	"Lisu",
	"Vai",
	"Bamum",
	"Syloti_Nagri",
	"Phags_Pa",
	"Saurashtra",
	"Kayah_Li",
	"Rejang",
	"Javanese",
	"Cham",
	"Tai_Viet",
	"Meetei_Mayek",
	"null",
	"Linear_B",
	"Lycian",
	"Carian",
	"Old_Italic",
	"Gothic",
	"Old_Permic",
	"Ugaritic",
	"Old_Persian",
	"Deseret",
	"Shavian",
	"Osmanya",
	"Osage",
	"Elbasan",
	"Caucasian_Albanian",
	"Linear_A",
	"Cypriot",
	"Imperial_Aramaic",
	"Palmyrene",
	"Nabataean",
	"Hatran",
	"Phoenician",
	"Lydian",
	"Meroitic_Hieroglyphs",
	"Meroitic_Cursive",
	"Kharoshthi",
	"Old_South_Arabian",
	"Old_North_Arabian",
	"Manichaean",
	"Avestan",
	"Inscriptional_Parthian",
	"Inscriptional_Pahlavi",
	"Psalter_Pahlavi",
	"Old_Turkic",
	"Old_Hungarian",
	"Hanifi_Rohingya",
	"Old_Sogdian",
	"Sogdian",
	"Elymaic",
	"Brahmi",
	"Kaithi",
	"Sora_Sompeng",
	"Chakma",
	"Mahajani",
	"Sharada",
	"Khojki",
	"Multani",
	"Khudawadi",
	"Grantha",
	"Newa",
	"Tirhuta",
	"Siddham",
	"Modi",
	"Takri",
	"Ahom",
	"Dogra",
	"Warang_Citi",
	"Nandinagari",
	"Zanabazar_Square",
	"Soyombo",
	"Pau_Cin_Hau",
	"Bhaiksuki",
	"Marchen",
	"Masaram_Gondi",
	"Gunjala_Gondi",
	"Makasar",
	"Cuneiform",
	"Egyptian_Hieroglyphs",
	"Anatolian_Hieroglyphs",
	"Mro",
	"Bassa_Vah",
	"Pahawh_Hmong",
	"Medefaidrin",
	"Miao",
	"Tangut",
	"Nushu",
	"Duployan",
	"SignWriting",
	"Nyiakeng_Puachue_Hmong",
	"Wancho",
	"Mende_Kikakui",
	"Adlam"
];
var eaw = [
	"N",
	"Na",
	"A",
	"W",
	"H",
	"F"
];
var data$1 = {
	categories: categories,
	combiningClasses: combiningClasses,
	scripts: scripts,
	eaw: eaw
};

var type = "Buffer";
var data = [
	0,
	17,
	0,
	0,
	0,
	0,
	0,
	0,
	0,
	1,
	124,
	240,
	101,
	121,
	121,
	56,
	212,
	109,
	251,
	254,
	91,
	61,
	81,
	89,
	138,
	100,
	95,
	42,
	132,
	208,
	36,
	217,
	183,
	236,
	33,
	132,
	236,
	140,
	52,
	143,
	117,
	44,
	77,
	98,
	172,
	163,
	34,
	59,
	147,
	12,
	178,
	78,
	33,
	20,
	205,
	32,
	251,
	54,
	33,
	51,
	133,
	102,
	34,
	140,
	125,
	48,
	49,
	89,
	7,
	19,
	131,
	49,
	227,
	215,
	243,
	126,
	143,
	183,
	158,
	227,
	248,
	253,
	247,
	89,
	142,
	251,
	254,
	92,
	215,
	121,
	159,
	247,
	125,
	157,
	231,
	245,
	89,
	155,
	230,
	42,
	20,
	241,
	137,
	153,
	221,
	204,
	52,
	1,
	72,
	70,
	16,
	171,
	26,
	138,
	194,
	123,
	10,
	188,
	100,
	46,
	112,
	88,
	126,
	234,
	222,
	156,
	39,
	191,
	118,
	120,
	244,
	1,
	102,
	254,
	82,
	247,
	37,
	210,
	194,
	76,
	163,
	127,
	243,
	248,
	79,
	185,
	147,
	54,
	114,
	0,
	191,
	87,
	78,
	86,
	19,
	37,
	71,
	217,
	95,
	149,
	181,
	11,
	244,
	151,
	137,
	130,
	234,
	172,
	142,
	241,
	189,
	53,
	56,
	255,
	192,
	182,
	249,
	235,
	165,
	64,
	81,
	25,
	203,
	163,
	107,
	239,
	70,
	47,
	61,
	75,
	120,
	22,
	255,
	125,
	226,
	134,
	29,
	158,
	94,
	144,
	231,
	227,
	64,
	72,
	91,
	180,
	68,
	151,
	254,
	220,
	161,
	18,
	136,
	132,
	1,
	101,
	2,
	35,
	173,
	108,
	176,
	211,
	183,
	169,
	140,
	111,
	129,
	179,
	48,
	93,
	96,
	54,
	123,
	215,
	254,
	192,
	113,
	181,
	187,
	0,
	34,
	55,
	243,
	180,
	35,
	92,
	66,
	55,
	13,
	14,
	210,
	211,
	133,
	27,
	238,
	94,
	238,
	76,
	230,
	15,
	194,
	6,
	54,
	139,
	4,
	78,
	37,
	38,
	110,
	42,
	203,
	250,
	25,
	15,
	222,
	254,
	32,
	154,
	242,
	2,
	151,
	23,
	126,
	5,
	152,
	150,
	75,
	214,
	110,
	23,
	215,
	130,
	232,
	206,
	36,
	165,
	110,
	240,
	31,
	168,
	206,
	164,
	203,
	111,
	28,
	137,
	230,
	67,
	98,
	71,
	190,
	66,
	196,
	58,
	159,
	4,
	207,
	94,
	58,
	80,
	204,
	152,
	103,
	168,
	103,
	72,
	144,
	109,
	180,
	185,
	85,
	74,
	101,
	35,
	2,
	62,
	100,
	213,
	196,
	41,
	5,
	73,
	198,
	92,
	42,
	142,
	19,
	152,
	239,
	13,
	215,
	192,
	247,
	172,
	108,
	178,
	185,
	37,
	145,
	62,
	78,
	222,
	23,
	242,
	175,
	36,
	155,
	71,
	115,
	34,
	123,
	15,
	63,
	54,
	236,
	114,
	184,
	193,
	49,
	88,
	197,
	230,
	99,
	135,
	73,
	228,
	93,
	37,
	88,
	10,
	82,
	50,
	211,
	30,
	33,
	234,
	93,
	35,
	228,
	122,
	159,
	13,
	255,
	49,
	100,
	243,
	94,
	187,
	40,
	186,
	123,
	229,
	254,
	145,
	157,
	217,
	47,
	205,
	215,
	193,
	233,
	76,
	247,
	50,
	106,
	175,
	68,
	10,
	243,
	116,
	38,
	236,
	215,
	248,
	145,
	102,
	54,
	15,
	44,
	177,
	119,
	83,
	196,
	44,
	248,
	127,
	145,
	111,
	200,
	138,
	231,
	86,
	186,
	52,
	133,
	118,
	87,
	223,
	180,
	238,
	226,
	108,
	76,
	97,
	86,
	164,
	252,
	252,
	39,
	239,
	187,
	137,
	27,
	182,
	7,
	167,
	128,
	42,
	82,
	191,
	146,
	232,
	235,
	46,
	216,
	112,
	104,
	63,
	251,
	107,
	162,
	121,
	195,
	221,
	19,
	186,
	201,
	199,
	212,
	147,
	0,
	115,
	232,
	13,
	174,
	169,
	120,
	106,
	202,
	78,
	80,
	111,
	209,
	174,
	204,
	84,
	42,
	99,
	243,
	132,
	120,
	15,
	224,
	25,
	94,
	174,
	239,
	186,
	85,
	247,
	63,
	48,
	24,
	224,
	93,
	92,
	123,
	194,
	26,
	61,
	226,
	115,
	231,
	236,
	195,
	197,
	174,
	124,
	16,
	63,
	184,
	52,
	147,
	64,
	234,
	210,
	29,
	134,
	72,
	163,
	186,
	125,
	54,
	124,
	156,
	186,
	29,
	55,
	84,
	162,
	57,
	72,
	255,
	228,
	66,
	255,
	94,
	141,
	67,
	6,
	244,
	102,
	211,
	9,
	171,
	135,
	124,
	122,
	166,
	23,
	9,
	219,
	79,
	253,
	10,
	227,
	43,
	230,
	31,
	22,
	62,
	165,
	204,
	73,
	171,
	11,
	160,
	177,
	232,
	185,
	244,
	230,
	19,
	185,
	179,
	98,
	187,
	58,
	7,
	71,
	88,
	236,
	168,
	238,
	10,
	136,
	10,
	170,
	203,
	241,
	159,
	16,
	48,
	93,
	107,
	221,
	244,
	251,
	156,
	244,
	249,
	196,
	240,
	51,
	135,
	79,
	98,
	13,
	31,
	20,
	101,
	35,
	37,
	71,
	174,
	166,
	90,
	134,
	215,
	213,
	216,
	79,
	192,
	216,
	240,
	221,
	180,
	13,
	222,
	194,
	84,
	71,
	50,
	209,
	72,
	4,
	163,
	191,
	79,
	130,
	40,
	205,
	100,
	208,
	103,
	233,
	155,
	250,
	7,
	74,
	186,
	79,
	217,
	30,
	186,
	165,
	162,
	231,
	10,
	118,
	195,
	146,
	38,
	105,
	86,
	77,
	41,
	237,
	90,
	55,
	49,
	84,
	200,
	212,
	154,
	186,
	23,
	44,
	205,
	116,
	35,
	164,
	240,
	12,
	185,
	193,
	171,
	130,
	207,
	235,
	212,
	241,
	129,
	87,
	178,
	26,
	199,
	124,
	200,
	123,
	225,
	23,
	204,
	157,
	62,
	90,
	205,
	69,
	35,
	108,
	244,
	67,
	251,
	90,
	103,
	174,
	72,
	78,
	203,
	187,
	93,
	9,
	108,
	235,
	144,
	112,
	156,
	63,
	235,
	123,
	3,
	167,
	175,
	48,
	7,
	216,
	176,
	63,
	144,
	14,
	250,
	96,
	105,
	87,
	68,
	121,
	84,
	48,
	222,
	201,
	5,
	5,
	9,
	233,
	117,
	27,
	238,
	10,
	28,
	24,
	4,
	84,
	84,
	180,
	96,
	175,
	166,
	218,
	197,
	203,
	88,
	187,
	134,
	78,
	89,
	21,
	1,
	223,
	123,
	91,
	2,
	138,
	117,
	167,
	35,
	88,
	73,
	81,
	10,
	224,
	209,
	106,
	231,
	247,
	222,
	95,
	242,
	85,
	192,
	197,
	234,
	197,
	79,
	41,
	228,
	222,
	179,
	223,
	69,
	11,
	83,
	105,
	53,
	103,
	41,
	137,
	225,
	146,
	87,
	242,
	27,
	59,
	195,
	194,
	79,
	46,
	105,
	109,
	68,
	117,
	83,
	201,
	113,
	205,
	254,
	19,
	251,
	46,
	159,
	100,
	204,
	78,
	207,
	114,
	236,
	134,
	68,
	11,
	121,
	224,
	210,
	119,
	69,
	166,
	82,
	180,
	32,
	106,
	57,
	155,
	146,
	218,
	241,
	177,
	189,
	128,
	103,
	64,
	2,
	195,
	101,
	96,
	158,
	47,
	160,
	87,
	249,
	190,
	42,
	240,
	145,
	88,
	184,
	52,
	240,
	41,
	113,
	238,
	232,
	106,
	151,
	235,
	253,
	95,
	36,
	134,
	114,
	56,
	206,
	30,
	91,
	41,
	242,
	240,
	112,
	253,
	239,
	7,
	75,
	183,
	109,
	138,
	161,
	122,
	79,
	245,
	220,
	73,
	123,
	154,
	49,
	110,
	186,
	24,
	93,
	96,
	226,
	175,
	88,
	200,
	22,
	157,
	30,
	172,
	183,
	99,
	152,
	226,
	159,
	87,
	59,
	201,
	154,
	44,
	232,
	16,
	107,
	233,
	219,
	40,
	153,
	33,
	241,
	137,
	65,
	218,
	157,
	139,
	92,
	97,
	12,
	39,
	48,
	198,
	97,
	70,
	19,
	167,
	105,
	130,
	94,
	200,
	156,
	218,
	70,
	235,
	164,
	200,
	37,
	33,
	227,
	207,
	158,
	252,
	21,
	47,
	147,
	246,
	132,
	124,
	58,
	124,
	106,
	15,
	163,
	15,
	174,
	1,
	31,
	19,
	103,
	67,
	155,
	255,
	19,
	181,
	145,
	147,
	183,
	139,
	140,
	101,
	117,
	220,
	141,
	232,
	171,
	46,
	57,
	96,
	211,
	141,
	139,
	253,
	104,
	167,
	90,
	3,
	175,
	186,
	207,
	142,
	72,
	79,
	177,
	53,
	224,
	82,
	148,
	45,
	24,
	51,
	229,
	34,
	206,
	121,
	42,
	20,
	26,
	137,
	207,
	100,
	72,
	107,
	63,
	82,
	182,
	118,
	64,
	164,
	14,
	126,
	80,
	124,
	49,
	49,
	16,
	197,
	17,
	125,
	166,
	233,
	101,
	78,
	133,
	142,
	236,
	74,
	101,
	106,
	126,
	166,
	112,
	196,
	69,
	157,
	75,
	182,
	227,
	141,
	114,
	198,
	45,
	183,
	199,
	226,
	170,
	42,
	90,
	251,
	69,
	101,
	172,
	139,
	214,
	87,
	49,
	105,
	107,
	242,
	247,
	207,
	222,
	89,
	100,
	214,
	109,
	143,
	70,
	61,
	50,
	185,
	215,
	28,
	196,
	27,
	168,
	107,
	181,
	95,
	34,
	4,
	102,
	156,
	83,
	198,
	95,
	163,
	230,
	213,
	98,
	31,
	50,
	111,
	209,
	155,
	137,
	255,
	197,
	57,
	252,
	32,
	106,
	128,
	208,
	101,
	174,
	170,
	11,
	184,
	242,
	145,
	20,
	59,
	69,
	61,
	156,
	223,
	153,
	116,
	139,
	197,
	164,
	246,
	251,
	84,
	51,
	233,
	132,
	135,
	209,
	103,
	28,
	231,
	148,
	119,
	149,
	21,
	230,
	228,
	119,
	217,
	126,
	173,
	165,
	242,
	244,
	26,
	83,
	49,
	194,
	20,
	34,
	240,
	95,
	128,
	113,
	27,
	234,
	119,
	70,
	163,
	197,
	84,
	147,
	26,
	98,
	121,
	253,
	120,
	42,
	60,
	197,
	57,
	198,
	128,
	53,
	248,
	47,
	22,
	155,
	242,
	99,
	201,
	68,
	242,
	81,
	56,
	173,
	161,
	226,
	204,
	186,
	101,
	184,
	252,
	204,
	146,
	205,
	97,
	199,
	247,
	181,
	30,
	137,
	57,
	201,
	93,
	41,
	238,
	164,
	7,
	236,
	102,
	152,
	120,
	204,
	243,
	101,
	49,
	111,
	70,
	155,
	250,
	147,
	168,
	154,
	39,
	140,
	141,
	171,
	48,
	111,
	55,
	194,
	123,
	7,
	141,
	244,
	135,
	108,
	5,
	155,
	82,
	1,
	61,
	202,
	155,
	162,
	237,
	247,
	27,
	191,
	205,
	88,
	248,
	40,
	166,
	94,
	12,
	150,
	89,
	90,
	233,
	237,
	184,
	47,
	116,
	206,
	77,
	83,
	100,
	205,
	173,
	179,
	191,
	116,
	10,
	184,
	53,
	147,
	120,
	58,
	66,
	148,
	33,
	24,
	129,
	52,
	62,
	230,
	241,
	1,
	73,
	6,
	108,
	254,
	179,
	5,
	231,
	79,
	172,
	155,
	136,
	232,
	125,
	56,
	22,
	46,
	168,
	23,
	215,
	29,
	211,
	146,
	176,
	196,
	85,
	164,
	106,
	125,
	143,
	241,
	102,
	44,
	197,
	103,
	118,
	60,
	252,
	49,
	68,
	20,
	61,
	245,
	85,
	39,
	193,
	67,
	154,
	218,
	16,
	30,
	167,
	30,
	118,
	97,
	233,
	215,
	94,
	134,
	215,
	37,
	64,
	159,
	31,
	6,
	185,
	93,
	200,
	136,
	125,
	244,
	179,
	103,
	87,
	125,
	135,
	204,
	161,
	30,
	143,
	78,
	33,
	147,
	164,
	152,
	141,
	214,
	174,
	9,
	174,
	26,
	97,
	109,
	43,
	187,
	244,
	49,
	189,
	4,
	70,
	204,
	178,
	142,
	1,
	11,
	51,
	127,
	120,
	177,
	39,
	42,
	83,
	91,
	117,
	150,
	47,
	160,
	75,
	72,
	93,
	132,
	48,
	35,
	8,
	195,
	179,
	148,
	189,
	96,
	66,
	232,
	15,
	224,
	13,
	205,
	131,
	214,
	59,
	193,
	106,
	147,
	2,
	115,
	188,
	237,
	199,
	168,
	216,
	115,
	60,
	19,
	42,
	196,
	75,
	47,
	167,
	76,
	53,
	118,
	252,
	220,
	116,
	188,
	145,
	208,
	244,
	165,
	51,
	12,
	143,
	94,
	181,
	205,
	181,
	71,
	145,
	77,
	38,
	133,
	33,
	232,
	245,
	41,
	65,
	12,
	53,
	221,
	55,
	168,
	67,
	189,
	17,
	162,
	206,
	106,
	33,
	180,
	140,
	145,
	222,
	139,
	124,
	180,
	94,
	98,
	80,
	110,
	6,
	44,
	215,
	23,
	120,
	107,
	193,
	185,
	212,
	133,
	172,
	123,
	190,
	109,
	86,
	91,
	246,
	56,
	171,
	53,
	81,
	190,
	173,
	3,
	200,
	231,
	80,
	59,
	30,
	239,
	63,
	111,
	69,
	30,
	104,
	134,
	153,
	225,
	72,
	3,
	59,
	175,
	15,
	145,
	140,
	119,
	60,
	77,
	135,
	90,
	146,
	154,
	164,
	158,
	250,
	224,
	172,
	109,
	229,
	49,
	88,
	167,
	44,
	12,
	203,
	128,
	203,
	66,
	165,
	81,
	203,
	93,
	72,
	237,
	35,
	139,
	135,
	21,
	5,
	154,
	168,
	132,
	43,
	157,
	141,
	106,
	40,
	214,
	2,
	178,
	31,
	10,
	14,
	170,
	27,
	202,
	185,
	121,
	40,
	176,
	68,
	34,
	91,
	213,
	30,
	198,
	12,
	145,
	187,
	225,
	58,
	216,
	131,
	31,
	229,
	86,
	174,
	240,
	247,
	239,
	138,
	135,
	221,
	189,
	197,
	34,
	68,
	106,
	132,
	172,
	123,
	151,
	212,
	57,
	8,
	61,
	156,
	192,
	39,
	33,
	155,
	254,
	237,
	252,
	21,
	69,
	195,
	111,
	222,
	3,
	135,
	35,
	142,
	149,
	111,
	152,
	216,
	80,
	46,
	186,
	61,
	129,
	206,
	234,
	84,
	190,
	76,
	233,
	175,
	17,
	8,
	238,
	98,
	108,
	120,
	70,
	71,
	223,
	96,
	108,
	106,
	100,
	138,
	127,
	12,
	245,
	141,
	28,
	8,
	177,
	109,
	16,
	92,
	249,
	114,
	132,
	220,
	145,
	14,
	130,
	206,
	35,
	35,
	11,
	191,
	117,
	32,
	48,
	135,
	39,
	107,
	184,
	225,
	133,
	223,
	215,
	40,
	247,
	86,
	147,
	179,
	206,
	73,
	39,
	118,
	127,
	46,
	149,
	232,
	23,
	141,
	225,
	83,
	2,
	108,
	111,
	243,
	53,
	10,
	28,
	221,
	159,
	193,
	215,
	242,
	242,
	215,
	158,
	165,
	148,
	42,
	244,
	97,
	52,
	186,
	95,
	0,
	94,
	15,
	76,
	90,
	175,
	107,
	91,
	1,
	64,
	109,
	9,
	251,
	159,
	71,
	201,
	135,
	230,
	67,
	122,
	26,
	227,
	251,
	49,
	101,
	214,
	169,
	84,
	185,
	104,
	253,
	250,
	40,
	197,
	37,
	226,
	253,
	211,
	132,
	65,
	98,
	22,
	171,
	193,
	52,
	22,
	75,
	109,
	19,
	36,
	197,
	93,
	29,
	154,
	52,
	195,
	200,
	52,
	194,
	51,
	103,
	0,
	141,
	18,
	23,
	152,
	77,
	89,
	109,
	177,
	214,
	139,
	144,
	204,
	138,
	160,
	69,
	37,
	184,
	243,
	64,
	96,
	35,
	54,
	32,
	109,
	219,
	175,
	64,
	203,
	203,
	71,
	213,
	53,
	98,
	135,
	181,
	180,
	138,
	108,
	24,
	223,
	139,
	14,
	203,
	187,
	78,
	160,
	64,
	86,
	165,
	11,
	180,
	66,
	244,
	28,
	17,
	49,
	42,
	75,
	96,
	53,
	40,
	246,
	144,
	237,
	13,
	252,
	240,
	82,
	35,
	201,
	40,
	178,
	118,
	44,
	103,
	18,
	166,
	21,
	129,
	105,
	211,
	136,
	254,
	34,
	99,
	54,
	165,
	7,
	87,
	187,
	156,
	27,
	204,
	130,
	235,
	232,
	141,
	51,
	212,
	24,
	18,
	90,
	129,
	71,
	177,
	2,
	21,
	122,
	220,
	78,
	5,
	144,
	221,
	225,
	191,
	180,
	37,
	154,
	113,
	63,
	91,
	196,
	137,
	31,
	155,
	19,
	230,
	139,
	123,
	206,
	119,
	126,
	138,
	198,
	40,
	64,
	36,
	14,
	247,
	189,
	99,
	73,
	249,
	143,
	104,
	106,
	149,
	205,
	177,
	197,
	82,
	86,
	164,
	185,
	131,
	94,
	113,
	57,
	99,
	67,
	253,
	107,
	148,
	9,
	192,
	61,
	96,
	0,
	32,
	233,
	97,
	91,
	140,
	118,
	166,
	173,
	185,
	89,
	229,
	205,
	11,
	233,
	156,
	117,
	95,
	159,
	25,
	138,
	219,
	70,
	113,
	240,
	202,
	83,
	175,
	123,
	65,
	20,
	170,
	91,
	248,
	62,
	70,
	135,
	205,
	71,
	43,
	235,
	88,
	202,
	193,
	159,
	142,
	28,
	62,
	249,
	17,
	123,
	97,
	221,
	78,
	239,
	194,
	171,
	77,
	66,
	231,
	250,
	226,
	88,
	241,
	217,
	201,
	115,
	202,
	30,
	228,
	131,
	13,
	213,
	188,
	115,
	85,
	207,
	129,
	222,
	168,
	130,
	242,
	225,
	219,
	74,
	132,
	34,
	239,
	28,
	215,
	219,
	42,
	175,
	139,
	159,
	200,
	122,
	240,
	135,
	126,
	217,
	55,
	147,
	36,
	189,
	141,
	93,
	176,
	105,
	132,
	207,
	218,
	84,
	5,
	60,
	86,
	174,
	251,
	225,
	55,
	35,
	131,
	201,
	125,
	144,
	214,
	79,
	223,
	109,
	127,
	211,
	254,
	246,
	199,
	53,
	192,
	107,
	154,
	56,
	3,
	182,
	240,
	93,
	181,
	154,
	213,
	182,
	34,
	32,
	230,
	37,
	208,
	187,
	51,
	191,
	12,
	67,
	47,
	69,
	127,
	12,
	139,
	92,
	242,
	153,
	41,
	109,
	247,
	96,
	70,
	32,
	183,
	138,
	34,
	216,
	232,
	74,
	18,
	87,
	19,
	241,
	251,
	199,
	9,
	223,
	208,
	135,
	176,
	111,
	192,
	69,
	137,
	226,
	125,
	49,
	249,
	68,
	4,
	36,
	24,
	16,
	57,
	188,
	121,
	153,
	226,
	136,
	67,
	223,
	26,
	134,
	152,
	138,
	33,
	29,
	133,
	12,
	71,
	64,
	218,
	246,
	2,
	228,
	93,
	153,
	178,
	253,
	153,
	201,
	117,
	240,
	171,
	166,
	135,
	225,
	196,
	100,
	186,
	122,
	207,
	232,
	247,
	228,
	194,
	253,
	205,
	54,
	212,
	193,
	168,
	84,
	100,
	44,
	144,
	64,
	232,
	16,
	172,
	103,
	221,
	125,
	11,
	90,
	71,
	243,
	152,
	39,
	255,
	93,
	97,
	118,
	246,
	77,
	102,
	69,
	50,
	40,
	223,
	8,
	15,
	13,
	162,
	254,
	44,
	107,
	145,
	69,
	13,
	71,
	181,
	79,
	175,
	33,
	226,
	173,
	60,
	88,
	49,
	59,
	37,
	182,
	195,
	137,
	232,
	248,
	186,
	212,
	76,
	71,
	218,
	39,
	181,
	235,
	241,
	149,
	123,
	250,
	46,
	226,
	83,
	94,
	221,
	88,
	18,
	255,
	27,
	15,
	49,
	45,
	235,
	205,
	207,
	185,
	43,
	8,
	55,
	70,
	159,
	190,
	237,
	43,
	112,
	27,
	189,
	1,
	181,
	217,
	110,
	67,
	134,
	220,
	173,
	192,
	134,
	218,
	144,
	219,
	195,
	118,
	222,
	85,
	230,
	237,
	45,
	216,
	159,
	162,
	151,
	152,
	8,
	65,
	135,
	63,
	134,
	122,
	138,
	201,
	39,
	133,
	241,
	59,
	246,
	247,
	189,
	153,
	54,
	45,
	122,
	120,
	219,
	228,
	47,
	177,
	92,
	70,
	72,
	213,
	105,
	125,
	39,
	45,
	32,
	83,
	19,
	71,
	86,
	195,
	246,
	222,
	244,
	139,
	171,
	31,
	52,
	93,
	214,
	43,
	63,
	238,
	40,
	49,
	3,
	10,
	156,
	243,
	206,
	220,
	33,
	47,
	142,
	101,
	179,
	15,
	61,
	31,
	98,
	41,
	88,
	221,
	116,
	177,
	113,
	209,
	150,
	16,
	236,
	123,
	210,
	130,
	118,
	147,
	249,
	168,
	211,
	105,
	83,
	206,
	28,
	77,
	143,
	154,
	138,
	171,
	48,
	169,
	31,
	23,
	103,
	215,
	154,
	208,
	166,
	213,
	71,
	4,
	110,
	56,
	100,
	20,
	221,
	203,
	0,
	181,
	93,
	159,
	73,
	185,
	234,
	227,
	181,
	58,
	122,
	206,
	171,
	42,
	85,
	139,
	90,
	24,
	55,
	179,
	240,
	125,
	11,
	216,
	62,
	161,
	60,
	169,
	233,
	27,
	72,
	149,
	212,
	121,
	212,
	227,
	164,
	63,
	159,
	250,
	157,
	44,
	186,
	138,
	213,
	21,
	61,
	195,
	13,
	58,
	158,
	12,
	254,
	16,
	221,
	40,
	236,
	247,
	184,
	174,
	172,
	173,
	183,
	113,
	58,
	183,
	54,
	112,
	152,
	43,
	213,
	40,
	52,
	218,
	128,
	113,
	197,
	41,
	50,
	233,
	94,
	201,
	104,
	111,
	179,
	136,
	220,
	37,
	126,
	169,
	227,
	158,
	248,
	118,
	254,
	234,
	181,
	148,
	167,
	219,
	167,
	234,
	169,
	194,
	175,
	195,
	255,
	147,
	136,
	230,
	9,
	5,
	151,
	144,
	158,
	140,
	161,
	177,
	125,
	205,
	152,
	84,
	151,
	198,
	169,
	206,
	4,
	211,
	30,
	94,
	244,
	241,
	202,
	218,
	233,
	27,
	28,
	210,
	59,
	253,
	22,
	85,
	232,
	179,
	94,
	235,
	150,
	169,
	109,
	49,
	163,
	160,
	38,
	144,
	150,
	205,
	45,
	196,
	243,
	219,
	153,
	6,
	194,
	66,
	127,
	191,
	186,
	144,
	168,
	93,
	22,
	114,
	247,
	44,
	232,
	173,
	98,
	111,
	103,
	66,
	150,
	113,
	181,
	229,
	7,
	21,
	63,
	119,
	146,
	56,
	17,
	21,
	19,
	196,
	203,
	149,
	90,
	20,
	140,
	152,
	94,
	187,
	236,
	179,
	77,
	236,
	217,
	203,
	194,
	201,
	138,
	107,
	5,
	228,
	10,
	6,
	235,
	165,
	201,
	110,
	58,
	93,
	22,
	10,
	20,
	117,
	170,
	27,
	82,
	175,
	17,
	151,
	30,
	237,
	185,
	213,
	209,
	8,
	181,
	136,
	88,
	95,
	123,
	39,
	190,
	125,
	96,
	75,
	107,
	151,
	187,
	55,
	83,
	196,
	34,
	99,
	102,
	216,
	236,
	138,
	157,
	34,
	79,
	107,
	3,
	11,
	74,
	140,
	75,
	176,
	100,
	246,
	203,
	182,
	55,
	37,
	227,
	20,
	174,
	127,
	127,
	150,
	104,
	91,
	10,
	31,
	131,
	112,
	237,
	79,
	40,
	218,
	22,
	171,
	41,
	156,
	227,
	147,
	74,
	31,
	49,
	43,
	240,
	138,
	80,
	84,
	185,
	238,
	11,
	149,
	18,
	76,
	61,
	198,
	132,
	46,
	68,
	44,
	48,
	16,
	239,
	75,
	187,
	220,
	246,
	189,
	140,
	82,
	107,
	211,
	69,
	87,
	192,
	239,
	75,
	27,
	246,
	11,
	133,
	152,
	45,
	129,
	124,
	54,
	183,
	56,
	15,
	53,
	164,
	204,
	219,
	36,
	243,
	82,
	73,
	58,
	86,
	127,
	45,
	130,
	107,
	24,
	42,
	35,
	224,
	201,
	153,
	166,
	119,
	247,
	1,
	121,
	179,
	197,
	62,
	43,
	182,
	95,
	99,
	178,
	169,
	99,
	19,
	39,
	157,
	207,
	142,
	188,
	72,
	98,
	157,
	6,
	36,
	51,
	203,
	63,
	234,
	88,
	161,
	33,
	22,
	14,
	57,
	101,
	204,
	229,
	101,
	157,
	202,
	24,
	189,
	229,
	234,
	201,
	254,
	221,
	52,
	218,
	223,
	51,
	21,
	50,
	19,
	218,
	214,
	134,
	198,
	167,
	252,
	254,
	126,
	246,
	52,
	65,
	116,
	235,
	37,
	55,
	245,
	92,
	4,
	247,
	18,
	212,
	29,
	81,
	246,
	24,
	150,
	101,
	153,
	202,
	243,
	217,
	255,
	170,
	214,
	193,
	78,
	212,
	57,
	7,
	162,
	238,
	149,
	68,
	210,
	139,
	32,
	55,
	222,
	8,
	203,
	228,
	245,
	250,
	137,
	132,
	235,
	62,
	17,
	135,
	99,
	182,
	208,
	88,
	224,
	5,
	226,
	182,
	203,
	89,
	156,
	160,
	88,
	140,
	12,
	226,
	111,
	185,
	147,
	231,
	228,
	202,
	50,
	245,
	249,
	133,
	31,
	124,
	50,
	124,
	171,
	231,
	227,
	228,
	240,
	124,
	187,
	237,
	254,
	81,
	211,
	77,
	89,
	239,
	174,
	218,
	20,
	89,
	16,
	98,
	65,
	120,
	98,
	52,
	207,
	39,
	19,
	166,
	104,
	89,
	235,
	177,
	253,
	157,
	27,
	218,
	233,
	209,
	113,
	213,
	51,
	116,
	58,
	102,
	47,
	176,
	128,
	129,
	221,
	83,
	161,
	21,
	240,
	130,
	97,
	211,
	100,
	232,
	34,
	26,
	213,
	83,
	251,
	26,
	245,
	64,
	127,
	78,
	109,
	230,
	209,
	77,
	62,
	13,
	204,
	191,
	151,
	74,
	146,
	238,
	185,
	106,
	122,
	216,
	106,
	144,
	188,
	149,
	83,
	62,
	134,
	78,
	120,
	144,
	155,
	234,
	2,
	48,
	119,
	213,
	199,
	49,
	251,
	80,
	248,
	167,
	52,
	240,
	130,
	138,
	116,
	164,
	0,
	53,
	34,
	117,
	107,
	104,
	47,
	123,
	114,
	93,
	70,
	19,
	180,
	250,
	211,
	98,
	99,
	53,
	128,
	101,
	247,
	123,
	138,
	202,
	30,
	6,
	238,
	187,
	91,
	188,
	110,
	43,
	197,
	151,
	238,
	120,
	73,
	81,
	25,
	93,
	247,
	194,
	39,
	111,
	75,
	127,
	117,
	99,
	146,
	106,
	239,
	228,
	14,
	245,
	120,
	12,
	154,
	63,
	112,
	137,
	176,
	244,
	3,
	182,
	26,
	250,
	180,
	23,
	105,
	244,
	51,
	188,
	136,
	100,
	162,
	252,
	106,
	99,
	27,
	141,
	137,
	93,
	250,
	161,
	101,
	179,
	184,
	106,
	240,
	7,
	47,
	171,
	222,
	202,
	216,
	250,
	194,
	106,
	137,
	174,
	82,
	39,
	172,
	187,
	186,
	141,
	106,
	113,
	174,
	164,
	73,
	122,
	39,
	248,
	158,
	155,
	201,
	114,
	175,
	243,
	76,
	158,
	196,
	235,
	234,
	142,
	53,
	60,
	237,
	50,
	133,
	8,
	119,
	132,
	241,
	77,
	211,
	2,
	79,
	237,
	47,
	55,
	210,
	55,
	189,
	137,
	48,
	75,
	205,
	67,
	96,
	96,
	127,
	160,
	81,
	154,
	81,
	58,
	141,
	235,
	55,
	106,
	160,
	8,
	143,
	186,
	25,
	236,
	41,
	95,
	204,
	60,
	8,
	127,
	171,
	163,
	164,
	43,
	90,
	36,
	173,
	170,
	103,
	12,
	205,
	86,
	183,
	188,
	71,
	165,
	251,
	127,
	221,
	15,
	19,
	41,
	210,
	176,
	239,
	213,
	14,
	176,
	76,
	196,
	183,
	43,
	174,
	51,
	77,
	62,
	145,
	91,
	50,
	181,
	203,
	174,
	55,
	138,
	109,
	185,
	37,
	174,
	215,
	217,
	227,
	194,
	87,
	105,
	147,
	179,
	7,
	127,
	226,
	59,
	33,
	100,
	250,
	56,
	108,
	185,
	222,
	174,
	162,
	40,
	178,
	175,
	225,
	203,
	102,
	202,
	80,
	9,
	149,
	159,
	217,
	248,
	162,
	233,
	245,
	249,
	173,
	3,
	33,
	158,
	122,
	82,
	21,
	112,
	153,
	145,
	221,
	80,
	218,
	75,
	107,
	200,
	38,
	62,
	252,
	82,
	213,
	227,
	111,
	8,
	93,
	83,
	211,
	10,
	53,
	192,
	163,
	221,
	8,
	152,
	180,
	168,
	234,
	214,
	207,
	186,
	24,
	69,
	60,
	224,
	78,
	217,
	51,
	19,
	54,
	103,
	223,
	79,
	23,
	72,
	188,
	20,
	162,
	14,
	14,
	88,
	98,
	137,
	204,
	41,
	255,
	220,
	87,
	197,
	246,
	32,
	55,
	217,
	214,
	71,
	74,
	253,
	244,
	165,
	16,
	140,
	220,
	160,
	88,
	245,
	102,
	104,
	70,
	27,
	93,
	43,
	240,
	161,
	30,
	78,
	131,
	17,
	0,
	108,
	125,
	94,
	173,
	55,
	106,
	53,
	10,
	125,
	38,
	46,
	211,
	193,
	126,
	121,
	18,
	250,
	172,
	181,
	47,
	108,
	95,
	21,
	239,
	83,
	64,
	251,
	67,
	19,
	80,
	94,
	227,
	132,
	83,
	151,
	205,
	132,
	246,
	19,
	211,
	218,
	180,
	182,
	251,
	221,
	203,
	21,
	188,
	225,
	65,
	3,
	183,
	11,
	248,
	67,
	218,
	52,
	69,
	31,
	112,
	86,
	89,
	182,
	172,
	1,
	251,
	140,
	133,
	18,
	69,
	113,
	58,
	226,
	239,
	119,
	132,
	243,
	126,
	114,
	211,
	94,
	172,
	219,
	96,
	227,
	160,
	82,
	124,
	235,
	101,
	175,
	46,
	62,
	189,
	126,
	237,
	147,
	129,
	33,
	119,
	68,
	120,
	154,
	244,
	62,
	67,
	74,
	43,
	101,
	64,
	5,
	216,
	10,
	118,
	221,
	170,
	51,
	228,
	71,
	111,
	59,
	46,
	30,
	157,
	8,
	112,
	218,
	166,
	139,
	62,
	120,
	80,
	239,
	113,
	147,
	207,
	217,
	231,
	79,
	153,
	49,
	113,
	206,
	114,
	68,
	252,
	64,
	75,
	161,
	4,
	52,
	66,
	105,
	253,
	18,
	70,
	78,
	200,
	153,
	135,
	196,
	198,
	239,
	41,
	134,
	252,
	163,
	109,
	89,
	30,
	107,
	249,
	96,
	186,
	160,
	176,
	67,
	228,
	246,
	5,
	153,
	193,
	132,
	24,
	247,
	2,
	234,
	25,
	123,
	154,
	199,
	255,
	16,
	149,
	94,
	106,
	13,
	163,
	219,
	171,
	218,
	75,
	61,
	61,
	114,
	167,
	236,
	206,
	88,
	169,
	171,
	104,
	76,
	181,
	57,
	66,
	72,
	239,
	55,
	68,
	112,
	73,
	175,
	6,
	150,
	221,
	192,
	186,
	195,
	10,
	1,
	27,
	219,
	21,
	252,
	75,
	249,
	241,
	102,
	8,
	202,
	225,
	180,
	98,
	250,
	150,
	206,
	153,
	184,
	124,
	108,
	71,
	168,
	62,
	245,
	63,
	47,
	217,
	221,
	199,
	217,
	39,
	182,
	80,
	192,
	45,
	183,
	64,
	190,
	157,
	14,
	241,
	144,
	183,
	205,
	37,
	39,
	22,
	14,
	28,
	120,
	251,
	48,
	238,
	60,
	94,
	193,
	107,
	203,
	236,
	116,
	82,
	4,
	181,
	39,
	232,
	158,
	239,
	76,
	127,
	110,
	149,
	222,
	77,
	239,
	162,
	69,
	62,
	0,
	66,
	117,
	113,
	133,
	219,
	109,
	104,
	16,
	153,
	62,
	249,
	119,
	209,
	193,
	122,
	101,
	45,
	44,
	171,
	84,
	238,
	199,
	21,
	96,
	68,
	179,
	223,
	103,
	77,
	172,
	202,
	189,
	229,
	183,
	30,
	150,
	195,
	225,
	209,
	255,
	98,
	103,
	64,
	151,
	233,
	112,
	109,
	43,
	124,
	5,
	5,
	178,
	76,
	110,
	227,
	2,
	192,
	2,
	35,
	182,
	207,
	61,
	69,
	61,
	132,
	143,
	87,
	80,
	117,
	170,
	64,
	235,
	158,
	204,
	124,
	212,
	142,
	124,
	121,
	66,
	171,
	58,
	79,
	90,
	141,
	29,
	244,
	211,
	30,
	87,
	131,
	182,
	106,
	138,
	237,
	249,
	64,
	245,
	173,
	32,
	207,
	105,
	242,
	112,
	138,
	115,
	212,
	81,
	193,
	185,
	108,
	74,
	54,
	213,
	30,
	77,
	218,
	143,
	219,
	209,
	91,
	222,
	219,
	254,
	182,
	94,
	73,
	200,
	26,
	151,
	161,
	31,
	175,
	10,
	199,
	115,
	103,
	6,
	84,
	133,
	254,
	139,
	187,
	198,
	239,
	144,
	31,
	114,
	64,
	254,
	49,
	236,
	97,
	158,
	128,
	29,
	50,
	155,
	66,
	233,
	151,
	77,
	208,
	237,
	239,
	130,
	209,
	192,
	236,
	155,
	209,
	146,
	13,
	234,
	66,
	14,
	56,
	187,
	157,
	75,
	37,
	47,
	38,
	50,
	150,
	227,
	223,
	188,
	188,
	138,
	104,
	52,
	151,
	227,
	26,
	81,
	250,
	117,
	88,
	66,
	52,
	16,
	159,
	145,
	124,
	41,
	182,
	53,
	129,
	178,
	21,
	46,
	34,
	181,
	39,
	121,
	150,
	247,
	76,
	211,
	201,
	29,
	91,
	168,
	10,
	87,
	216,
	25,
	86,
	135,
	178,
	134,
	240,
	176,
	24,
	247,
	244,
	189,
	157,
	96,
	180,
	93,
	120,
	125,
	165,
	150,
	104,
	235,
	27,
	162,
	175,
	93,
	147,
	227,
	122,
	228,
	104,
	103,
	208,
	178,
	198,
	208,
	200,
	139,
	37,
	3,
	187,
	137,
	157,
	33,
	206,
	87,
	109,
	141,
	134,
	83,
	109,
	82,
	141,
	28,
	207,
	19,
	192,
	177,
	120,
	97,
	242,
	210,
	16,
	97,
	164,
	204,
	226,
	95,
	242,
	2,
	86,
	101,
	201,
	212,
	220,
	94,
	25,
	70,
	219,
	97,
	172,
	254,
	125,
	193,
	196,
	160,
	93,
	255,
	118,
	99,
	99,
	8,
	55,
	53,
	165,
	46,
	173,
	85,
	225,
	52,
	31,
	154,
	125,
	89,
	197,
	124,
	218,
	253,
	77,
	176,
	189,
	146,
	195,
	93,
	191,
	10,
	77,
	125,
	225,
	218,
	159,
	34,
	124,
	51,
	161,
	171,
	33,
	87,
	203,
	149,
	128,
	15,
	191,
	66,
	11,
	123,
	135,
	103,
	209,
	159,
	129,
	37,
	38,
	201,
	144,
	222,
	222,
	101,
	143,
	220,
	235,
	238,
	173,
	180,
	54,
	28,
	32,
	55,
	148,
	40,
	128,
	215,
	169,
	68,
	132,
	227,
	101,
	221,
	135,
	153,
	209,
	110,
	230,
	142,
	110,
	140,
	227,
	134,
	221,
	7,
	39,
	145,
	245,
	123,
	231,
	166,
	248,
	210,
	98,
	255,
	239,
	36,
	66,
	92,
	163,
	9,
	178,
	105,
	61,
	201,
	48,
	78,
	22,
	94,
	32,
	95,
	127,
	187,
	185,
	107,
	84,
	130,
	141,
	120,
	146,
	194,
	55,
	244,
	233,
	60,
	7,
	151,
	198,
	247,
	119,
	215,
	240,
	148,
	13,
	97,
	202,
	244,
	203,
	143,
	213,
	93,
	83,
	38,
	9,
	224,
	206,
	46,
	21,
	210,
	172,
	210,
	221,
	37,
	183,
	67,
	46,
	84,
	136,
	97,
	234,
	131,
	77,
	176,
	65,
	174,
	109,
	176,
	73,
	125,
	179,
	245,
	253,
	156,
	211,
	40,
	178,
	2,
	229,
	82,
	6,
	82,
	124,
	32,
	99,
	212,
	56,
	178,
	164,
	183,
	225,
	175,
	223,
	235,
	210,
	116,
	116,
	93,
	141,
	173,
	154,
	16,
	206,
	113,
	68,
	184,
	19,
	183,
	42,
	107,
	129,
	9,
	6,
	28,
	234,
	190,
	150,
	217,
	107,
	126,
	80,
	228,
	140,
	249,
	18,
	169,
	161,
	98,
	35,
	134,
	12,
	6,
	36,
	235,
	78,
	147,
	191,
	190,
	62,
	40,
	103,
	207,
	140,
	165,
	110,
	63,
	94,
	107,
	35,
	56,
	22,
	208,
	142,
	255,
	158,
	35,
	174,
	245,
	233,
	127,
	178,
	125,
	155,
	71,
	39,
	180,
	129,
	95,
	134,
	173,
	227,
	195,
	68,
	51,
	7,
	143,
	3,
	181,
	148,
	71,
	94,
	92,
	127,
	13,
	249,
	68,
	206,
	220,
	138,
	13,
	215,
	201,
	41,
	35,
	123,
	79,
	199,
	63,
	159,
	99,
	233,
	246,
	48,
	99,
	18,
	24,
	81,
	192,
	199,
	255,
	55,
	180,
	167,
	137,
	58,
	95,
	182,
	139,
	231,
	245,
	59,
	219,
	250,
	21,
	241,
	3,
	5,
	186,
	171,
	195,
	233,
	105,
	51,
	192,
	39,
	106,
	254,
	243,
	28,
	218,
	251,
	132,
	78,
	252,
	157,
	117,
	45,
	60,
	218,
	29,
	49,
	176,
	167,
	33,
	53,
	128,
	236,
	198,
	191,
	32,
	121,
	89,
	231,
	65,
	125,
	133,
	104,
	30,
	255,
	12,
	206,
	189,
	38,
	237,
	168,
	85,
	69,
	182,
	68,
	188,
	57,
	253,
	167,
	178,
	200,
	217,
	193,
	180,
	133,
	117,
	79,
	149,
	59,
	204,
	250,
	180,
	33,
	28,
	245,
	64,
	61,
	0,
	44,
	83,
	231,
	50,
	20,
	121,
	42,
	228,
	4,
	31,
	239,
	214,
	74,
	206,
	166,
	110,
	219,
	84,
	202,
	198,
	196,
	66,
	159,
	35,
	162,
	220,
	158,
	144,
	120,
	15,
	198,
	239,
	159,
	104,
	67,
	40,
	106,
	138,
	172,
	150,
	149,
	0,
	155,
	131,
	104,
	17,
	24,
	220,
	94,
	222,
	95,
	42,
	167,
	21,
	105,
	78,
	160,
	85,
	127,
	241,
	146,
	236,
	165,
	152,
	153,
	119,
	186,
	43,
	92,
	184,
	93,
	226,
	104,
	71,
	66,
	6,
	238,
	41,
	177,
	227,
	37,
	101,
	51,
	174,
	121,
	180,
	15,
	28,
	63,
	44,
	146,
	243,
	92,
	41,
	33,
	238,
	201,
	76,
	196,
	170,
	60,
	187,
	22,
	72,
	89,
	151,
	221,
	40,
	121,
	95,
	35,
	187,
	163,
	220,
	179,
	250,
	1,
	16,
	46,
	89,
	209,
	123,
	102,
	102,
	123,
	117,
	163,
	138,
	168,
	161,
	155,
	207,
	146,
	241,
	58,
	8,
	19,
	129,
	45,
	253,
	160,
	72,
	168,
	98,
	106,
	234,
	144,
	39,
	148,
	14,
	246,
	241,
	144,
	22,
	10,
	120,
	120,
	92,
	46,
	99,
	13,
	241,
	211,
	5,
	211,
	86,
	76,
	111,
	125,
	136,
	164,
	191,
	220,
	31,
	196,
	123,
	84,
	116,
	18,
	35,
	146,
	52,
	97,
	242,
	245,
	226,
	15,
	93,
	236,
	255,
	250,
	114,
	48,
	117,
	37,
	117,
	241,
	110,
	140,
	196,
	13,
	187,
	43,
	61,
	217,
	139,
	242,
	152,
	123,
	65,
	109,
	116,
	142,
	42,
	62,
	191,
	182,
	44,
	168,
	114,
	144,
	235,
	26,
	193,
	108,
	229,
	109,
	197,
	77,
	63,
	156,
	87,
	243,
	80,
	102,
	112,
	32,
	18,
	240,
	201,
	75,
	224,
	227,
	216,
	162,
	251,
	158,
	39,
	191,
	209,
	114,
	16,
	32,
	105,
	81,
	25,
	208,
	43,
	131,
	177,
	232,
	222,
	229,
	195,
	151,
	236,
	141,
	215,
	121,
	151,
	253,
	146,
	192,
	23,
	0,
	28,
	194,
	104,
	202,
	51,
	181,
	62,
	39,
	242,
	123,
	103,
	90,
	129,
	163,
	176,
	9,
	83,
	42,
	116,
	149,
	128,
	163,
	190,
	233,
	188,
	23,
	180,
	205,
	210,
	92,
	77,
	107,
	185,
	125,
	220,
	207,
	53,
	245,
	118,
	167,
	90,
	92,
	239,
	242,
	74,
	67,
	204,
	225,
	42,
	76,
	44,
	56,
	66,
	212,
	14,
	21,
	242,
	128,
	242,
	121,
	161,
	31,
	21,
	166,
	77,
	165,
	176,
	39,
	167,
	220,
	61,
	208,
	57,
	10,
	118,
	205,
	218,
	210,
	100,
	120,
	213,
	6,
	203,
	67,
	23,
	252,
	22,
	59,
	223,
	43,
	174,
	68,
	58,
	255,
	188,
	237,
	252,
	58,
	226,
	167,
	179,
	135,
	34,
	232,
	53,
	150,
	137,
	141,
	118,
	100,
	90,
	29,
	26,
	227,
	154,
	167,
	168,
	118,
	125,
	182,
	177,
	235,
	16,
	64,
	248,
	51,
	138,
	99,
	206,
	50,
	160,
	186,
	209,
	174,
	126,
	139,
	245,
	24,
	135,
	157,
	110,
	102,
	111,
	162,
	141,
	105,
	36,
	104,
	141,
	177,
	142,
	176,
	78,
	219,
	158,
	124,
	13,
	147,
	89,
	50,
	43,
	131,
	66,
	86,
	149,
	2,
	240,
	114,
	219,
	2,
	231,
	253,
	180,
	119,
	22,
	179,
	115,
	126,
	115,
	191,
	246,
	128,
	243,
	181,
	138,
	177,
	102,
	186,
	251,
	36,
	194,
	33,
	170,
	106,
	232,
	114,
	22,
	234,
	153,
	9,
	202,
	163,
	12,
	86,
	219,
	214,
	233,
	169,
	54,
	137,
	187,
	185,
	22,
	213,
	90,
	45,
	11,
	189,
	144,
	145,
	133,
	252,
	58,
	128,
	195,
	116,
	192,
	240,
	87,
	89,
	9,
	70,
	77,
	230,
	185,
	248,
	9,
	7,
	55,
	254,
	139,
	1,
	81,
	39,
	181,
	214,
	67,
	3,
	185,
	186,
	53,
	96,
	150,
	1,
	96,
	30,
	220,
	156,
	90,
	35,
	11,
	199,
	147,
	126,
	233,
	117,
	62,
	78,
	233,
	75,
	105,
	181,
	163,
	59,
	58,
	26,
	163,
	77,
	223,
	158,
	37,
	22,
	211,
	27,
	200,
	36,
	211,
	219,
	147,
	176,
	144,
	148,
	86,
	93,
	253,
	16,
	142,
	62,
	248,
	16,
	108,
	184,
	225,
	228,
	132,
	196,
	240,
	213,
	73,
	120,
	171,
	119,
	28,
	106,
	250,
	37,
	57,
	197,
	116,
	140,
	220,
	84,
	233,
	68,
	183,
	10,
	155,
	32,
	133,
	244,
	32,
	206,
	236,
	104,
	47,
	184,
	55,
	157,
	56,
	236,
	196,
	201,
	247,
	229,
	167,
	215,
	81,
	33,
	200,
	67,
	200,
	151,
	11,
	7,
	19,
	155,
	173,
	9,
	229,
	209,
	141,
	12,
	229,
	173,
	31,
	145,
	212,
	39,
	255,
	168,
	60,
	220,
	53,
	186,
	139,
	93,
	217,
	55,
	39,
	184,
	16,
	223,
	213,
	84,
	174,
	177,
	36,
	75,
	245,
	76,
	90,
	56,
	224,
	250,
	221,
	168,
	98,
	186,
	129,
	210,
	213,
	25,
	10,
	87,
	20,
	23,
	175,
	139,
	4,
	45,
	196,
	67,
	178,
	167,
	24,
	140,
	38,
	254,
	168,
	217,
	95,
	129,
	138,
	62,
	216,
	211,
	95,
	92,
	208,
	41,
	94,
	167,
	60,
	6,
	246,
	183,
	244,
	115,
	107,
	146,
	42,
	41,
	23,
	252,
	139,
	96,
	197,
	18,
	21,
	127,
	97,
	40,
	159,
	95,
	70,
	48,
	178,
	33,
	122,
	77,
	9,
	233,
	227,
	193,
	171,
	235,
	148,
	197,
	171,
	76,
	212,
	123,
	162,
	198,
	142,
	38,
	59,
	126,
	173,
	11,
	72,
	98,
	139,
	213,
	89,
	194,
	232,
	68,
	136,
	219,
	44,
	111,
	183,
	117,
	234,
	7,
	30,
	119,
	7,
	120,
	18,
	148,
	97,
	94,
	37,
	195,
	219,
	81,
	186,
	39,
	91,
	81,
	61,
	19,
	217,
	184,
	53,
	251,
	176,
	62,
	31,
	173,
	229,
	8,
	209,
	197,
	125,
	248,
	150,
	240,
	79,
	87,
	28,
	16,
	119,
	182,
	158,
	77,
	223,
	171,
	245,
	220,
	57,
	10,
	35,
	245,
	166,
	223,
	48,
	49,
	154,
	12,
	118,
	120,
	187,
	25,
	170,
	88,
	118,
	142,
	138,
	62,
	7,
	144,
	41,
	201,
	165,
	21,
	250,
	184,
	81,
	136,
	111,
	87,
	66,
	1,
	129,
	232,
	145,
	221,
	25,
	211,
	48,
	22,
	170,
	14,
	170,
	89,
	173,
	52,
	114,
	45,
	219,
	41,
	226,
	93,
	8,
	93,
	148,
	221,
	18,
	183,
	199,
	210,
	195,
	119,
	118,
	160,
	70,
	230,
	244,
	72,
	199,
	117,
	96,
	9,
	135,
	7,
	154,
	105,
	42,
	39,
	193,
	242,
	187,
	123,
	61,
	43,
	219,
	129,
	15,
	87,
	124,
	113,
	149,
	4,
	199,
	122,
	152,
	112,
	94,
	111,
	178,
	167,
	145,
	164,
	93,
	70,
	58,
	15,
	30,
	153,
	229,
	140,
	216,
	233,
	129,
	149,
	99,
	221,
	103,
	170,
	128,
	95,
	9,
	183,
	156,
	67,
	69,
	202,
	31,
	164,
	189,
	121,
	53,
	223,
	99,
	223,
	88,
	9,
	77,
	210,
	70,
	251,
	202,
	30,
	31,
	207,
	100,
	251,
	85,
	101,
	24,
	254,
	17,
	23,
	95,
	144,
	239,
	196,
	96,
	1,
	11,
	77,
	215,
	70,
	156,
	142,
	27,
	203,
	127,
	187,
	113,
	161,
	230,
	214,
	93,
	166,
	196,
	149,
	244,
	5,
	154,
	196,
	147,
	123,
	207,
	8,
	85,
	130,
	88,
	8,
	35,
	88,
	21,
	219,
	203,
	84,
	86,
	44,
	170,
	159,
	133,
	12,
	116,
	136,
	121,
	183,
	225,
	26,
	219,
	58,
	59,
	60,
	238,
	60,
	161,
	182,
	180,
	79,
	4,
	27,
	137,
	38,
	78,
	31,
	134,
	33,
	15,
	158,
	252,
	144,
	241,
	119,
	173,
	235,
	154,
	63,
	178,
	238,
	140,
	243,
	44,
	140,
	121,
	154,
	14,
	226,
	29,
	245,
	113,
	26,
	100,
	16,
	137,
	133,
	181,
	201,
	109,
	24,
	57,
	84,
	41,
	55,
	40,
	154,
	107,
	200,
	127,
	254,
	241,
	45,
	240,
	118,
	20,
	120,
	238,
	151,
	69,
	114,
	106,
	89,
	27,
	106,
	116,
	6,
	152,
	233,
	253,
	86,
	239,
	106,
	88,
	8,
	100,
	201,
	101,
	97,
	126,
	75,
	223,
	86,
	140,
	61,
	219,
	117,
	39,
	186,
	255,
	239,
	6,
	226,
	198,
	221,
	12,
	159,
	61,
	153,
	188,
	209,
	147,
	1,
	17,
	207,
	84,
	63,
	182,
	59,
	80,
	252,
	72,
	106,
	86,
	94,
	85,
	103,
	63,
	103,
	164,
	130,
	137,
	249,
	229,
	136,
	162,
	156,
	0,
	195,
	202,
	124,
	154,
	94,
	214,
	67,
	94,
	231,
	149,
	169,
	187,
	147,
	139,
	204,
	252,
	112,
	113,
	202,
	223,
	110,
	105,
	130,
	163,
	62,
	153,
	102,
	112,
	204,
	119,
	132,
	173,
	227,
	47,
	133,
	154,
	41,
	1,
	39,
	23,
	51,
	94,
	18,
	36,
	122,
	70,
	193,
	156,
	145,
	150,
	51,
	37,
	14,
	247,
	12,
	155,
	209,
	89,
	255,
	87,
	189,
	250,
	191,
	233,
	184,
	233,
	233,
	92,
	235,
	92,
	244,
	139,
	182,
	126,
	104,
	1,
	124,
	67,
	253,
	112,
	101,
	181,
	23,
	54,
	245,
	172,
	105,
	95,
	39,
	79,
	41,
	0,
	43,
	106,
	213,
	149,
	181,
	241,
	183,
	246,
	113,
	235,
	222,
	111,
	53,
	94,
	29,
	182,
	128,
	231,
	65,
	189,
	170,
	133,
	41,
	223,
	210,
	86,
	116,
	241,
	219,
	98,
	67,
	194,
	69,
	120,
	237,
	226,
	12,
	229,
	171,
	112,
	177,
	129,
	237,
	188,
	50,
	76,
	188,
	50,
	132,
	191,
	248,
	233,
	251,
	218,
	71,
	62,
	100,
	141,
	177,
	6,
	216,
	9,
	202,
	218,
	64,
	64,
	116,
	53,
	206,
	177,
	29,
	116,
	194,
	21,
	201,
	30,
	110,
	173,
	126,
	178,
	4,
	219,
	188,
	191,
	153,
	74,
	11,
	199,
	139,
	116,
	98,
	147,
	38,
	214,
	224,
	33,
	157,
	217,
	201,
	60,
	210,
	125,
	105,
	177,
	245,
	255,
	156,
	189,
	194,
	94,
	243,
	35,
	55,
	244,
	175,
	221,
	142,
	74,
	118,
	105,
	68,
	196,
	255,
	217,
	182,
	46,
	62,
	99,
	131,
	182,
	252,
	189,
	212,
	64,
	122,
	190,
	195,
	59,
	116,
	178,
	44,
	209,
	236,
	212,
	32,
	38,
	231,
	30,
	98,
	1,
	216,
	118,
	200,
	98,
	88,
	138,
	240,
	43,
	101,
	176,
	129,
	53,
	143,
	60,
	79,
	15,
	176,
	248,
	45,
	83,
	110,
	222,
	240,
	43,
	124,
	229,
	218,
	188,
	211,
	30,
	143,
	195,
	243,
	248,
	27,
	221,
	205,
	230,
	98,
	185,
	196,
	32,
	72,
	169,
	98,
	163,
	212,
	113,
	213,
	163,
	157,
	59,
	18,
	195,
	142,
	245,
	59,
	113,
	20,
	55,
	177,
	173,
	131,
	131,
	78,
	2,
	207,
	79,
	38,
	188,
	102,
	211,
	117,
	110,
	100,
	166,
	185,
	199,
	5,
	192,
	96,
	40,
	237,
	236,
	107,
	52,
	212,
	169,
	186,
	79,
	96,
	225,
	57,
	133,
	12,
	160,
	87,
	2,
	74,
	238,
	199,
	147,
	90,
	119,
	200,
	253,
	94,
	27,
	227,
	55,
	191,
	116,
	57,
	150,
	224,
	51,
	123,
	165,
	176,
	6,
	118,
	72,
	96,
	237,
	19,
	10,
	231,
	15,
	88,
	175,
	148,
	39,
	126,
	138,
	160,
	159,
	24,
	250,
	250,
	90,
	190,
	18,
	206,
	242,
	14,
	94,
	119,
	189,
	56,
	34,
	57,
	80,
	117,
	247,
	166,
	172,
	203,
	191,
	252,
	75,
	207,
	97,
	116,
	208,
	72,
	98,
	15,
	61,
	248,
	154,
	128,
	148,
	189,
	156,
	202,
	143,
	228,
	139,
	228,
	196,
	223,
	105,
	190,
	226,
	115,
	238,
	31,
	28,
	106,
	179,
	215,
	76,
	163,
	42,
	242,
	57,
	211,
	120,
	189,
	18,
	77,
	169,
	250,
	118,
	114,
	211,
	2,
	172,
	8,
	179,
	7,
	8,
	193,
	96,
	209,
	216,
	172,
	27,
	25,
	215,
	64,
	84,
	223,
	18,
	97,
	100,
	65,
	238,
	71,
	193,
	124,
	20,
	100,
	73,
	124,
	239,
	30,
	82,
	5,
	240,
	62,
	163,
	163,
	174,
	36,
	83,
	54,
	36,
	230,
	143,
	231,
	242,
	130,
	227,
	42,
	166,
	173,
	210,
	183,
	87,
	189,
	15,
	58,
	50,
	76,
	16,
	24,
	201,
	231,
	149,
	198,
	172,
	168,
	243,
	211,
	219,
	233,
	149,
	181,
	65,
	155,
	223,
	98,
	174,
	31,
	167,
	50,
	101,
	7,
	167,
	173,
	17,
	181,
	213,
	48,
	43,
	112,
	9,
	118,
	239,
	233,
	239,
	56,
	192,
	67,
	123,
	239,
	132,
	40,
	119,
	248,
	97,
	220,
	134,
	127,
	185,
	179,
	159,
	51,
	179,
	223,
	119,
	5,
	20,
	97,
	28,
	191,
	125,
	189,
	167,
	138,
	134,
	218,
	26,
	26,
	67,
	197,
	11,
	136,
	204,
	149,
	207,
	161,
	13,
	115,
	24,
	104,
	168,
	105,
	197,
	33,
	219,
	169,
	114,
	126,
	198,
	32,
	36,
	22,
	187,
	13,
	242,
	59,
	117,
	188,
	75,
	125,
	230,
	204,
	39,
	12,
	96,
	159,
	50,
	193,
	206,
	212,
	238,
	185,
	154,
	65,
	255,
	214,
	228,
	174,
	183,
	26,
	190,
	27,
	85,
	58,
	87,
	59,
	35,
	169,
	226,
	214,
	218,
	63,
	34,
	26,
	106,
	208,
	77,
	165,
	122,
	165,
	153,
	5,
	19,
	73,
	149,
	227,
	196,
	250,
	245,
	247,
	238,
	247,
	61,
	38,
	147,
	249,
	9,
	200,
	223,
	150,
	44,
	90,
	126,
	150,
	165,
	242,
	99,
	70,
	210,
	188,
	190,
	82,
	89,
	161,
	52,
	160,
	141,
	164,
	84,
	223,
	208,
	6,
	10,
	69,
	142,
	102,
	216,
	159,
	38,
	185,
	222,
	124,
	42,
	234,
	193,
	50,
	131,
	22,
	107,
	150,
	80,
	43,
	164,
	18,
	198,
	28,
	70,
	179,
	51,
	10,
	136,
	173,
	219,
	204,
	24,
	164,
	254,
	228,
	73,
	88,
	204,
	15,
	197,
	137,
	97,
	8,
	141,
	115,
	102,
	229,
	50,
	59,
	170,
	97,
	232,
	38,
	127,
	164,
	172,
	124,
	45,
	99,
	171,
	63,
	23,
	206,
	8,
	76,
	148,
	229,
	254,
	130,
	127,
	204,
	41,
	153,
	160,
	150,
	59,
	51,
	174,
	103,
	161,
	17,
	174,
	21,
	247,
	197,
	107,
	120,
	144,
	241,
	98,
	241,
	235,
	222,
	62,
	200,
	120,
	144,
	125,
	254,
	192,
	89,
	87,
	141,
	65,
	89,
	63,
	192,
	100,
	8,
	238,
	11,
	149,
	232,
	174,
	82,
	15,
	157,
	152,
	1,
	2,
	120,
	129,
	18,
	22,
	167,
	167,
	79,
	244,
	250,
	166,
	102,
	17,
	96,
	233,
	187,
	200,
	160,
	123,
	88,
	84,
	60,
	140,
	147,
	163,
	164,
	250,
	128,
	49,
	28,
	22,
	21,
	146,
	161,
	219,
	141,
	135,
	198,
	18,
	187,
	174,
	74,
	128,
	195,
	134,
	7,
	14,
	213,
	148,
	214,
	51,
	52,
	136,
	48,
	30,
	218,
	189,
	40,
	253,
	178,
	201,
	53,
	114,
	178,
	165,
	249,
	191,
	93,
	172,
	127,
	176,
	254,
	189,
	5,
	29,
	71,
	103,
	81,
	14,
	240,
	149,
	227,
	74,
	200,
	34,
	117,
	210,
	45,
	100,
	255,
	202,
	176,
	186,
	19,
	19,
	144,
	196,
	18,
	112,
	32,
	54,
	222,
	101,
	201,
	119,
	207,
	92,
	145,
	229,
	24,
	247,
	151,
	194,
	6,
	167,
	27,
	41,
	106,
	153,
	239,
	4,
	191,
	159,
	194,
	234,
	64,
	221,
	190,
	159,
	144,
	87,
	30,
	32,
	63,
	112,
	130,
	35,
	53,
	32,
	176,
	116,
	112,
	117,
	93,
	190,
	143,
	209,
	227,
	24,
	60,
	202,
	162,
	151,
	79,
	199,
	159,
	151,
	65,
	167,
	25,
	125,
	127,
	219,
	177,
	84,
	97,
	229,
	22,
	216,
	191,
	198,
	53,
	17,
	134,
	89,
	81,
	169,
	222,
	47,
	117,
	161,
	162,
	100,
	111,
	138,
	242,
	108,
	67,
	71,
	173,
	239,
	90,
	216,
	254,
	111,
	139,
	63,
	242,
	170,
	79,
	39,
	129,
	101,
	20,
	75,
	226,
	175,
	249,
	251,
	190,
	244,
	18,
	197,
	157,
	242,
	226,
	45,
	115,
	133,
	112,
	135,
	129,
	36,
	184,
	103,
	107,
	32,
	162,
	184,
	52,
	82,
	169,
	204,
	116,
	66,
	238,
	188,
	129,
	91,
	6,
	97,
	206,
	132,
	28,
	62,
	116,
	208,
	89,
	45,
	250,
	10,
	67,
	143,
	168,
	47,
	175,
	207,
	56,
	159,
	204,
	25,
	230,
	142,
	170,
	239,
	27,
	75,
	231,
	0,
	52,
	109,
	229,
	208,
	157,
	247,
	214,
	98,
	70,
	107,
	179,
	99,
	70,
	33,
	24,
	16,
	110,
	199,
	57,
	52,
	29,
	244,
	217,
	37,
	113,
	249,
	30,
	17,
	58,
	249,
	160,
	101,
	164,
	124,
	192,
	23,
	35,
	139,
	93,
	188,
	4,
	199,
	201,
	246,
	71,
	47,
	39,
	128,
	194,
	249,
	180,
	57,
	238,
	68,
	102,
	191,
	156,
	177,
	129,
	78,
	39,
	129,
	151,
	231,
	211,
	242,
	77,
	103,
	194,
	65,
	195,
	24,
	211,
	169,
	47,
	172,
	246,
	72,
	201,
	20,
	109,
	110,
	156,
	101,
	6,
	47,
	3,
	34,
	34,
	229,
	212,
	15,
	209,
	5,
	132,
	210,
	9,
	187,
	32,
	132,
	2,
	101,
	89,
	72,
	129,
	138,
	31,
	129,
	186,
	191,
	88,
	17,
	71,
	82,
	242,
	205,
	2,
	135,
	217,
	154,
	162,
	35,
	80,
	231,
	28,
	202,
	3,
	17,
	155,
	41,
	108,
	56,
	211,
	121,
	161,
	72,
	1,
	180,
	175,
	141,
	42,
	183,
	3,
	233,
	248,
	235,
	47,
	97,
	133,
	83,
	60,
	192,
	138,
	103,
	1,
	20,
	151,
	203,
	57,
	142,
	15,
	222,
	157,
	245,
	69,
	239,
	22,
	186,
	18,
	62,
	1,
	176,
	213,
	218,
	182,
	189,
	209,
	42,
	14,
	175,
	95,
	36,
	221,
	68,
	108,
	189,
	150,
	186,
	83,
	102,
	30,
	65,
	67,
	191,
	20,
	142,
	245,
	148,
	255,
	69,
	127,
	193,
	195,
	104,
	0,
	252,
	26,
	197,
	247,
	69,
	235,
	36,
	0,
	158,
	173,
	229,
	95,
	109,
	95,
	216,
	93,
	162,
	159,
	167,
	190,
	205,
	155,
	30,
	77,
	140,
	66,
	20,
	151,
	26,
	35,
	179,
	46,
	226,
	55,
	185,
	193,
	29,
	38,
	98,
	129,
	251,
	27,
	23,
	37,
	78,
	95,
	86,
	83,
	120,
	32,
	177,
	190,
	169,
	209,
	72,
	76,
	79,
	226,
	89,
	57,
	101,
	230,
	37,
	70,
	239,
	92,
	105,
	166,
	187,
	227,
	116,
	214,
	53,
	215,
	33,
	68,
	136,
	68,
	91,
	6,
	157,
	176,
	18,
	30,
	219,
	142,
	66,
	241,
	201,
	105,
	105,
	158,
	51,
	172,
	251,
	36,
	248,
	236,
	23,
	207,
	21,
	130,
	189,
	20,
	110,
	155,
	35,
	82,
	45,
	110,
	74,
	89,
	164,
	137,
	200,
	149,
	61,
	229,
	204,
	33,
	120,
	174,
	250,
	130,
	190,
	246,
	219,
	203,
	41,
	103,
	120,
	102,
	78,
	210,
	242,
	173,
	243,
	54,
	65,
	17,
	106,
	100,
	83,
	17,
	243,
	244,
	58,
	201,
	103,
	213,
	131,
	4,
	221,
	125,
	125,
	145,
	203,
	140,
	55,
	249,
	224,
	14,
	255,
	149,
	83,
	230,
	113,
	91,
	228,
	217,
	115,
	92,
	251,
	193,
	16,
	5,
	171,
	53,
	158,
	144,
	222,
	54,
	199,
	209,
	17,
	120,
	189,
	198,
	233,
	249,
	240,
	231,
	129,
	255,
	242,
	194,
	154,
	50,
	53,
	11,
	99,
	173,
	251,
	220,
	171,
	221,
	192,
	85,
	189,
	81,
	13,
	100,
	96,
	134,
	252,
	170,
	128,
	141,
	129,
	144,
	202,
	245,
	175,
	6,
	34,
	242,
	8,
	179,
	103,
	65,
	189,
	26,
	99,
	233,
	49,
	234,
	143,
	122,
	176,
	16,
	209,
	210,
	91,
	207,
	31,
	138,
	0,
	83,
	56,
	32,
	26,
	186,
	137,
	34,
	233,
	100,
	117,
	43,
	161,
	236,
	159,
	119,
	218,
	57,
	163,
	238,
	31,
	71,
	97,
	45,
	177,
	144,
	104,
	36,
	228,
	80,
	16,
	14,
	18,
	91,
	64,
	166,
	121,
	116,
	167,
	110,
	92,
	118,
	123,
	212,
	36,
	8,
	114,
	40,
	119,
	42,
	149,
	235,
	232,
	57,
	17,
	49,
	41,
	102,
	194,
	234,
	157,
	223,
	205,
	107,
	173,
	4,
	40,
	245,
	255,
	181,
	10,
	79,
	17,
	195,
	190,
	8,
	226,
	1,
	61,
	196,
	29,
	179,
	48,
	243,
	74,
	33,
	198,
	201,
	1,
	79,
	151,
	47,
	251,
	41,
	200,
	148,
	135,
	91,
	190,
	152,
	87,
	241,
	76,
	33,
	198,
	191,
	217,
	231,
	217,
	106,
	167,
	116,
	104,
	30,
	26,
	52,
	63,
	146,
	189,
	54,
	170,
	100,
	193,
	95,
	177,
	220,
	214,
	168,
	132,
	190,
	8,
	146,
	207,
	84,
	243,
	254,
	204,
	187,
	18,
	205,
	189,
	194,
	249,
	250,
	22,
	176,
	198,
	60,
	254,
	218,
	177,
	121,
	97,
	57,
	168,
	135,
	196,
	231,
	45,
	62,
	244,
	233,
	180,
	80,
	53,
	144,
	113,
	84,
	147,
	198,
	204,
	19,
	180,
	172,
	77,
	41,
	198,
	108,
	86,
	212,
	174,
	203,
	49,
	174,
	174,
	244,
	46,
	26,
	18,
	135,
	147,
	176,
	189,
	9,
	218,
	251,
	15,
	56,
	93,
	213,
	225,
	101,
	189,
	172,
	150,
	130,
	120,
	102,
	192,
	160,
	222,
	63,
	143,
	174,
	50,
	75,
	211,
	207,
	201,
	6,
	46,
	115,
	25,
	222,
	174,
	119,
	246,
	113,
	134,
	103,
	6,
	40,
	179,
	151,
	61,
	195,
	110,
	157,
	10,
	185,
	17,
	37,
	189,
	218,
	34,
	111,
	126,
	203,
	145,
	172,
	214,
	45,
	107,
	5,
	176,
	74,
	99,
	97,
	61,
	138,
	5,
	143,
	100,
	42,
	88,
	209,
	116,
	125,
	242,
	233,
	26,
	223,
	218,
	119,
	110,
	17,
	39,
	203,
	228,
	111,
	155,
	23,
	146,
	156,
	138,
	94,
	107,
	197,
	27,
	149,
	92,
	119,
	55,
	255,
	227,
	137,
	55,
	60,
	18,
	255,
	162,
	50,
	121,
	219,
	233,
	22,
	27,
	51,
	128,
	174,
	124,
	169,
	229,
	255,
	122,
	246,
	133,
	119,
	39,
	103,
	56,
	46,
	45,
	187,
	54,
	74,
	47,
	107,
	213,
	112,
	68,
	213,
	74,
	168,
	221,
	21,
	250,
	5,
	126,
	153,
	49,
	226,
	182,
	9,
	23,
	87,
	125,
	26,
	89,
	221,
	192,
	213,
	20,
	162,
	149,
	209,
	37,
	122,
	35,
	79,
	3,
	238,
	92,
	167,
	237,
	254,
	79,
	181,
	254,
	197,
	241,
	226,
	69,
	149,
	208,
	214,
	206,
	5,
	3,
	212,
	241,
	33,
	201,
	239,
	121,
	11,
	61,
	37,
	38,
	121,
	175,
	154,
	141,
	154,
	10,
	165,
	91,
	140,
	204,
	181,
	158,
	171,
	96,
	219,
	224,
	155,
	37,
	92,
	203,
	2,
	173,
	187,
	198,
	113,
	157,
	38,
	210,
	230,
	245,
	229,
	5,
	95,
	141,
	93,
	210,
	31,
	139,
	127,
	177,
	206,
	185,
	201,
	209,
	57,
	5,
	47,
	229,
	39,
	242,
	253,
	79,
	88,
	224,
	182,
	60,
	241,
	66,
	141,
	83,
	234,
	238,
	164,
	199,
	238,
	99,
	169,
	204,
	230,
	35,
	253,
	190,
	33,
	209,
	199,
	147,
	249,
	225,
	224,
	175,
	222,
	201,
	170,
	22,
	245,
	206,
	37,
	95,
	120,
	3,
	115,
	124,
	223,
	27,
	21,
	72,
	79,
	40,
	24,
	187,
	132,
	144,
	66,
	186,
	68,
	39,
	7,
	70,
	134,
	140,
	84,
	216,
	44,
	147,
	215,
	47,
	110,
	165,
	67,
	102,
	252,
	211,
	26,
	206,
	15,
	151,
	5,
	90,
	0,
	60,
	135,
	61,
	210,
	183,
	71,
	186,
	246,
	76,
	13,
	156,
	61,
	254,
	157,
	125,
	224,
	203,
	93,
	133,
	142,
	184,
	206,
	249,
	215,
	81,
	190,
	251,
	223,
	201,
	93,
	210,
	89,
	181,
	220,
	198,
	163,
	71,
	126,
	107,
	27,
	246,
	237,
	83,
	87,
	236,
	7,
	77,
	191,
	65,
	211,
	191,
	203,
	218,
	192,
	124,
	89,
	65,
	201,
	245,
	231,
	141,
	162,
	96,
	242,
	202,
	193,
	22,
	103,
	71,
	94,
	168,
	72,
	71,
	254,
	133,
	136,
	218,
	136,
	132,
	135,
	157,
	186,
	130,
	240,
	10,
	212,
	220,
	118,
	144,
	136,
	77,
	190,
	70,
	17,
	250,
	211,
	216,
	28,
	70,
	189,
	143,
	233,
	221,
	213,
	80,
	105,
	64,
	253,
	110,
	205,
	40,
	200,
	12,
	239,
	159,
	12,
	48,
	123,
	187,
	45,
	54,
	246,
	180,
	99,
	12,
	46,
	82,
	147,
	166,
	138,
	115,
	60,
	179,
	212,
	62,
	110,
	3,
	210,
	238,
	249,
	26,
	235,
	106,
	153,
	124,
	37,
	115,
	75,
	221,
	84,
	101,
	148,
	212,
	7,
	216,
	173,
	89,
	23,
	19,
	169,
	95,
	194,
	233,
	174,
	219,
	95,
	169,
	14,
	122,
	49,
	179,
	204,
	208,
	139,
	109,
	82,
	23,
	151,
	88,
	63,
	230,
	68,
	227,
	158,
	22,
	161,
	148,
	108,
	191,
	164,
	80,
	229,
	101,
	92,
	180,
	197,
	95,
	254,
	118,
	230,
	14,
	31,
	104,
	177,
	5,
	59,
	138,
	150,
	89,
	217,
	142,
	92,
	195,
	111,
	86,
	58,
	72,
	85,
	53,
	173,
	235,
	157,
	7,
	125,
	8,
	215,
	246,
	132,
	247,
	14,
	14,
	64,
	187,
	175,
	175,
	215,
	240,
	162,
	239,
	194,
	218,
	233,
	147,
	167,
	129,
	128,
	133,
	61,
	61,
	64,
	83,
	12,
	80,
	185,
	157,
	50,
	183,
	156,
	74,
	116,
	213,
	203,
	104,
	52,
	200,
	72,
	196,
	222,
	10,
	61,
	122,
	93,
	58,
	242,
	132,
	205,
	250,
	43,
	105,
	159,
	25,
	18,
	32,
	162,
	222,
	245,
	190,
	133,
	172,
	242,
	212,
	233,
	47,
	247,
	245,
	174,
	247,
	48,
	191,
	114,
	210,
	13,
	255,
	191,
	27,
	196,
	199,
	9,
	62,
	205,
	165,
	241,
	214,
	113,
	111,
	165,
	176,
	95,
	232,
	151,
	202,
	189,
	6,
	46,
	140,
	123,
	51,
	83,
	170,
	157,
	43,
	166,
	159,
	228,
	37,
	24,
	149,
	124,
	218,
	215,
	255,
	13,
	187,
	113,
	20,
	83,
	187,
	196,
	72,
	34,
	171,
	84,
	110,
	80,
	76,
	147,
	28,
	184,
	143,
	0,
	21,
	168,
	40,
	212,
	38,
	72,
	143,
	6,
	61,
	156,
	2,
	133,
	101,
	160,
	111,
	1,
	219,
	243,
	121,
	117,
	226,
	221,
	50,
	16,
	219,
	239,
	27,
	57,
	158,
	23,
	119,
	118,
	230,
	201,
	134,
	232,
	252,
	89,
	182,
	133,
	71,
	43,
	181,
	47,
	174,
	252,
	12,
	120,
	97,
	3,
	234,
	204,
	138,
	99,
	104,
	224,
	45,
	100,
	26,
	154,
	116,
	180,
	63,
	234,
	156,
	39,
	194,
	20,
	119,
	163,
	165,
	253,
	11,
	37,
	126,
	55,
	57,
	58,
	249,
	239,
	195,
	216,
	210,
	217,
	200,
	34,
	185,
	86,
	200,
	214,
	50,
	185,
	31,
	226,
	123,
	168,
	246,
	196,
	214,
	245,
	57,
	94,
	174,
	209,
	211,
	151,
	255,
	80,
	237,
	113,
	222,
	254,
	201,
	175,
	86,
	250,
	182,
	54,
	46,
	105,
	242,
	64,
	203,
	100,
	241,
	35,
	11,
	78,
	137,
	252,
	68,
	7,
	112,
	57,
	163,
	118,
	180,
	216,
	57,
	205,
	89,
	195,
	42,
	89,
	149,
	120,
	236,
	79,
	87,
	107,
	225,
	28,
	138,
	61,
	64,
	67,
	213,
	238,
	55,
	55,
	127,
	236,
	213,
	14,
	171,
	162,
	54,
	13,
	78,
	104,
	117,
	247,
	113,
	149,
	201,
	13,
	22,
	57,
	48,
	154,
	104,
	249,
	86,
	167,
	1,
	201,
	170,
	167,
	138,
	26,
	177,
	130,
	198,
	251,
	10,
	215,
	80,
	190,
	111,
	37,
	77,
	36,
	170,
	77,
	161,
	24,
	75,
	36,
	132,
	255,
	137,
	142,
	40,
	43,
	207,
	89,
	122,
	66,
	113,
	48,
	167,
	169,
	252,
	60,
	197,
	94,
	38,
	158,
	244,
	34,
	46,
	169,
	165,
	230,
	138,
	244,
	104,
	69,
	233,
	242,
	68,
	211,
	71,
	30,
	47,
	44,
	111,
	211,
	203,
	74,
	136,
	246,
	173,
	225,
	7,
	113,
	100,
	88,
	18,
	109,
	9,
	39,
	156,
	72,
	130,
	44,
	63,
	16,
	115,
	90,
	227,
	107,
	53,
	74,
	51,
	122,
	247,
	129,
	121,
	231,
	49,
	227,
	141,
	129,
	220,
	255,
	200,
	85,
	117,
	171,
	232,
	90,
	104,
	223,
	176,
	156,
	93,
	197,
	159,
	139,
	152,
	8,
	143,
	87,
	176,
	163,
	174,
	87,
	0,
	195,
	208,
	187,
	2,
	59,
	247,
	244,
	130,
	62,
	92,
	155,
	16,
	133,
	114,
	190,
	236,
	114,
	82,
	28,
	121,
	245,
	163,
	190,
	138,
	222,
	251,
	118,
	146,
	83,
	142,
	248,
	115,
	42,
	131,
	87,
	17,
	251,
	195,
	84,
	87,
	220,
	33,
	236,
	106,
	171,
	12,
	69,
	125,
	123,
	100,
	184,
	35,
	176,
	53,
	240,
	179,
	121,
	11,
	155,
	101,
	201,
	116,
	205,
	202,
	187,
	127,
	55,
	81,
	122,
	154,
	30,
	171,
	50,
	195,
	51,
	225,
	199,
	179,
	69,
	138,
	189,
	5,
	170,
	60,
	166,
	255,
	14,
	156,
	196,
	61,
	148,
	247,
	42,
	90,
	221,
	220,
	123,
	103,
	93,
	176,
	253,
	248,
	183,
	38,
	155,
	60,
	178,
	162,
	124,
	127,
	192,
	33,
	241,
	166,
	64,
	190,
	65,
	215,
	188,
	132,
	114,
	174,
	204,
	74,
	238,
	186,
	197,
	104,
	211,
	93,
	54,
	248,
	200,
	243,
	237,
	196,
	154,
	184,
	181,
	247,
	144,
	194,
	53,
	175,
	57,
	241,
	169,
	227,
	217,
	7,
	103,
	203,
	160,
	179,
	178,
	25,
	39,
	224,
	221,
	190,
	214,
	111,
	164,
	229,
	47,
	44,
	186,
	117,
	247,
	37,
	174,
	200,
	248,
	155,
	173,
	71,
	56,
	53,
	153,
	205,
	158,
	245,
	135,
	52,
	102,
	255,
	79,
	102,
	125,
	82,
	194,
	28,
	72,
	117,
	79,
	108,
	95,
	6,
	81,
	77,
	223,
	201,
	228,
	90,
	20,
	155,
	71,
	190,
	61,
	132,
	68,
	120,
	120,
	55,
	161,
	205,
	152,
	99,
	49,
	159,
	219,
	23,
	201,
	197,
	113,
	49,
	183,
	138,
	83,
	221,
	23,
	195,
	68,
	123,
	204,
	92,
	181,
	38,
	161,
	115,
	178,
	235,
	124,
	111,
	230,
	146,
	238,
	132,
	225,
	26,
	199,
	71,
	81,
	129,
	44,
	176,
	103,
	241,
	135,
	249,
	128,
	87,
	22,
	0,
	158,
	77,
	77,
	33,
	103,
	196,
	143,
	16,
	91,
	39,
	242,
	155,
	167,
	167,
	82,
	174,
	173,
	221,
	74,
	129,
	124,
	84,
	103,
	233,
	90,
	221,
	30,
	126,
	225,
	227,
	198,
	71,
	134,
	69,
	229,
	68,
	45,
	57,
	175,
	55,
	249,
	88,
	252,
	206,
	31,
	52,
	117,
	252,
	219,
	135,
	21,
	130,
	122,
	130,
	103,
	67,
	135,
	100,
	100,
	147,
	56,
	118,
	188,
	228,
	157,
	147,
	142,
	43,
	29,
	167,
	221,
	106,
	239,
	38,
	124,
	197,
	33,
	47,
	41,
	211,
	23,
	162,
	115,
	227,
	55,
	101,
	21,
	87,
	34,
	157,
	116,
	181,
	93,
	131,
	201,
	130,
	176,
	71,
	18,
	243,
	186,
	83,
	240,
	204,
	119,
	11,
	149,
	132,
	241,
	151,
	131,
	197,
	238,
	255,
	37,
	215,
	78,
	128,
	206,
	191,
	186,
	192,
	17,
	94,
	141,
	186,
	191,
	220,
	254,
	246,
	11,
	253,
	35,
	239,
	199,
	30,
	165,
	143,
	125,
	247,
	79,
	221,
	225,
	224,
	46,
	174,
	125,
	165,
	169,
	209,
	138,
	45,
	79,
	5,
	125,
	249,
	204,
	195,
	183,
	24,
	238,
	149,
	94,
	92,
	148,
	76,
	83,
	189,
	206,
	199,
	8,
	182,
	131,
	107,
	225,
	255,
	238,
	32,
	95,
	199,
	111,
	110,
	55,
	232,
	142,
	127,
	222,
	45,
	88,
	97,
	98,
	95,
	6,
	182,
	28,
	2,
	67,
	157,
	10,
	113,
	154,
	184,
	207,
	98,
	94,
	91,
	151,
	158,
	58,
	114,
	86,
	21,
	1,
	39,
	87,
	16,
	232,
	27,
	142,
	125,
	77,
	160,
	184,
	205,
	124,
	155,
	16,
	33,
	40,
	39,
	69,
	121,
	171,
	91,
	87,
	173,
	6,
	195,
	50,
	213,
	49,
	121,
	53,
	122,
	61,
	219,
	117,
	255,
	50,
	110,
	115,
	180,
	201,
	161,
	95,
	253,
	97,
	145,
	86,
	197,
	75,
	66,
	127,
	163,
	174,
	221,
	87,
	127,
	189,
	31,
	239,
	27,
	77,
	119,
	242,
	18,
	80,
	172,
	121,
	13,
	122,
	135,
	169,
	229,
	210,
	222,
	251,
	171,
	166,
	113,
	42,
	174,
	42,
	243,
	26,
	22,
	153,
	183,
	17,
	145,
	195,
	4,
	235,
	194,
	139,
	69,
	216,
	200,
	129,
	71,
	50,
	5,
	251,
	10,
	170,
	119,
	37,
	85,
	229,
	196,
	145,
	112,
	65,
	241,
	210,
	104,
	133,
	4,
	108,
	141,
	174,
	214,
	80,
	85,
	81,
	217,
	77,
	195,
	132,
	26,
	245,
	75,
	11,
	60,
	66,
	189,
	225,
	8,
	165,
	159,
	122,
	114,
	43,
	20,
	21,
	9,
	227,
	101,
	199,
	204,
	157,
	236,
	71,
	160,
	84,
	212,
	1,
	144,
	82,
	218,
	108,
	171,
	231,
	137,
	235,
	88,
	84,
	30,
	8,
	61,
	157,
	95,
	74,
	138,
	252,
	217,
	170,
	219,
	129,
	135,
	156,
	241,
	164,
	157,
	20,
	198,
	136,
	150,
	230,
	19,
	98,
	227,
	113,
	223,
	100,
	177,
	126,
	184,
	202,
	81,
	211,
	198,
	171,
	229,
	13,
	167,
	150,
	41,
	201,
	218,
	4,
	27,
	124,
	85,
	46,
	44,
	109,
	152,
	115,
	189,
	205,
	124,
	74,
	2,
	113,
	231,
	62,
	225,
	246,
	124,
	241,
	171,
	143,
	29,
	6,
	114,
	224,
	131,
	17,
	79,
	178,
	93,
	75,
	87,
	135,
	169,
	223,
	80,
	191,
	156,
	202,
	76,
	44,
	214,
	2,
	121,
	190,
	71,
	216,
	21,
	161,
	65,
	175,
	211,
	221,
	9,
	123,
	213,
	127,
	126,
	78,
	37,
	12,
	115,
	80,
	201,
	150,
	142,
	253,
	22,
	234,
	225,
	137,
	43,
	93,
	110,
	0,
	96,
	234,
	74,
	147,
	176,
	9,
	254,
	171,
	162,
	228,
	136,
	140,
	245,
	96,
	195,
	158,
	220,
	36,
	35,
	144,
	225,
	191,
	109,
	144,
	143,
	216,
	229,
	99,
	104,
	159,
	212,
	176,
	123,
	189,
	154,
	102,
	188,
	138,
	163,
	199,
	126,
	31,
	33,
	193,
	17,
	213,
	137,
	42,
	185,
	26,
	175,
	45,
	11,
	32,
	103,
	51,
	3,
	130,
	43,
	171,
	126,
	119,
	89,
	205,
	25,
	129,
	173,
	232,
	155,
	181,
	16,
	136,
	1,
	130,
	188,
	137,
	124,
	24,
	108,
	154,
	171,
	62,
	115,
	31,
	237,
	96,
	48,
	254,
	105,
	133,
	101,
	2,
	164,
	171,
	109,
	181,
	98,
	50,
	137,
	204,
	244,
	246,
	245,
	224,
	239,
	161,
	177,
	146,
	104,
	134,
	72,
	196,
	52,
	233,
	159,
	87,
	240,
	21,
	48,
	168,
	235,
	90,
	184,
	54,
	207,
	33,
	254,
	43,
	164,
	175,
	242,
	98,
	135,
	128,
	31,
	18,
	125,
	178,
	133,
	210,
	245,
	80,
	222,
	212,
	12,
	62,
	107,
	3,
	142,
	197,
	163,
	124,
	126,
	130,
	235,
	137,
	253,
	19,
	92,
	27,
	208,
	231,
	151,
	109,
	70,
	219,
	165,
	70,
	175,
	94,
	152,
	80,
	193,
	198,
	84,
	28,
	168,
	160,
	154,
	15,
	195,
	94,
	77,
	33,
	59,
	20,
	225,
	239,
	114,
	55,
	255,
	250,
	130,
	155,
	110,
	212,
	106,
	207,
	216,
	27,
	161,
	250,
	28,
	216,
	76,
	208,
	222,
	146,
	250,
	91,
	146,
	93,
	124,
	156,
	192,
	90,
	99,
	164,
	242,
	135,
	168,
	16,
	39,
	189,
	210,
	85,
	76,
	174,
	236,
	37,
	254,
	91,
	76,
	211,
	94,
	79,
	255,
	50,
	15,
	107,
	58,
	175,
	118,
	241,
	102,
	44,
	213,
	71,
	48,
	134,
	107,
	194,
	36,
	54,
	180,
	130,
	173,
	26,
	30,
	143,
	13,
	81,
	254,
	243,
	151,
	176,
	221,
	198,
	170,
	86,
	221,
	129,
	176,
	59,
	249,
	172,
	231,
	46,
	220,
	111,
	108,
	173,
	5,
	157,
	236,
	217,
	160,
	183,
	48,
	145,
	81,
	114,
	216,
	146,
	118,
	17,
	75,
	11,
	71,
	246,
	133,
	45,
	146,
	172,
	141,
	220,
	39,
	207,
	219,
	76,
	80,
	213,
	246,
	151,
	66,
	94,
	12,
	18,
	104,
	208,
	122,
	21,
	145,
	232,
	105,
	134,
	158,
	71,
	105,
	96,
	212,
	24,
	120,
	129,
	184,
	211,
	204,
	128,
	95,
	187,
	130,
	102,
	102,
	20,
	137,
	207,
	99,
	184,
	231,
	219,
	249,
	23,
	182,
	243,
	227,
	149,
	56,
	31,
	172,
	113,
	130,
	184,
	13,
	163,
	220,
	5,
	239,
	185,
	194,
	104,
	226,
	246,
	12,
	166,
	182,
	67,
	41,
	42,
	142,
	20,
	42,
	160,
	179,
	239,
	44,
	115,
	184,
	223,
	21,
	34,
	92,
	125,
	200,
	218,
	51,
	165,
	18,
	105,
	53,
	165,
	135,
	181,
	166,
	85,
	18,
	163,
	248,
	230,
	125,
	137,
	122,
	100,
	229,
	96,
	20,
	166,
	52,
	130,
	181,
	22,
	228,
	152,
	1,
	68,
	53,
	200,
	18,
	231,
	43,
	23,
	175,
	174,
	54,
	178,
	119,
	232,
	118,
	139,
	195,
	227,
	116,
	29,
	216,
	99,
	166,
	244,
	158,
	189,
	201,
	184,
	229,
	198,
	164,
	243,
	50,
	135,
	133,
	118,
	118,
	201,
	105,
	71,
	112,
	197,
	18,
	248,
	41,
	176,
	18,
	234,
	134,
	162,
	195,
	57,
	99,
	248,
	200,
	75,
	200,
	47,
	167,
	246,
	212,
	250,
	64,
	239,
	255,
	1
];
var trieBuffer = {
	type: type,
	data: data
};

// we may be running in a browser so we make an Uint8Array

var trieData = new Uint8Array(trieBuffer.data);
var trie = new UnicodeTrie(trieData);

var log2 = Math.log2 || function (n) {
  return Math.log(n) / Math.LN2;
};

var bits = function bits(n) {
  return log2(n) + 1 | 0;
}; // compute the number of bits stored for each field


var CATEGORY_BITS = bits(data$1.categories.length - 1);
var COMBINING_BITS = bits(data$1.combiningClasses.length - 1);
var SCRIPT_BITS = bits(data$1.scripts.length - 1);
var EAW_BITS = bits(data$1.eaw.length - 1);
var NUMBER_BITS = 10; // compute shift and mask values for each field

var CATEGORY_SHIFT = COMBINING_BITS + SCRIPT_BITS + EAW_BITS + NUMBER_BITS;
var COMBINING_SHIFT = SCRIPT_BITS + EAW_BITS + NUMBER_BITS;
var SCRIPT_SHIFT = EAW_BITS + NUMBER_BITS;
var EAW_SHIFT = NUMBER_BITS;
var CATEGORY_MASK = (1 << CATEGORY_BITS) - 1;
var COMBINING_MASK = (1 << COMBINING_BITS) - 1;
var SCRIPT_MASK = (1 << SCRIPT_BITS) - 1;
var EAW_MASK = (1 << EAW_BITS) - 1;
var NUMBER_MASK = (1 << NUMBER_BITS) - 1;
var getCategory = function getCategory(codePoint) {
  var val = trie.get(codePoint);
  return data$1.categories[val >> CATEGORY_SHIFT & CATEGORY_MASK];
};
var getCombiningClass = function getCombiningClass(codePoint) {
  var val = trie.get(codePoint);
  return data$1.combiningClasses[val >> COMBINING_SHIFT & COMBINING_MASK];
};
var getScript = function getScript(codePoint) {
  var val = trie.get(codePoint);
  return data$1.scripts[val >> SCRIPT_SHIFT & SCRIPT_MASK];
};
var getEastAsianWidth = function getEastAsianWidth(codePoint) {
  var val = trie.get(codePoint);
  return data$1.eaw[val >> EAW_SHIFT & EAW_MASK];
};
var getNumericValue = function getNumericValue(codePoint) {
  var val = trie.get(codePoint);
  var num = val & NUMBER_MASK;

  if (num === 0) {
    return null;
  }

  if (num <= 50) {
    return num - 1;
  }

  if (num < 0x1e0) {
    var numerator = (num >> 4) - 12;
    var denominator = (num & 0xf) + 1;
    return numerator / denominator;
  }

  if (num < 0x300) {
    val = (num >> 5) - 14;

    var _exp = (num & 0x1f) + 2;

    while (_exp > 0) {
      val *= 10;
      _exp--;
    }

    return val;
  }

  val = (num >> 2) - 0xbf;
  var exp = (num & 3) + 1;

  while (exp > 0) {
    val *= 60;
    exp--;
  }

  return val;
};
var isAlphabetic = function isAlphabetic(codePoint) {
  var category = getCategory(codePoint);
  return category === 'Lu' || category === 'Ll' || category === 'Lt' || category === 'Lm' || category === 'Lo' || category === 'Nl';
};
var isDigit = function isDigit(codePoint) {
  return getCategory(codePoint) === 'Nd';
};
var isPunctuation = function isPunctuation(codePoint) {
  var category = getCategory(codePoint);
  return category === 'Pc' || category === 'Pd' || category === 'Pe' || category === 'Pf' || category === 'Pi' || category === 'Po' || category === 'Ps';
};
var isLowerCase = function isLowerCase(codePoint) {
  return getCategory(codePoint) === 'Ll';
};
var isUpperCase = function isUpperCase(codePoint) {
  return getCategory(codePoint) === 'Lu';
};
var isTitleCase = function isTitleCase(codePoint) {
  return getCategory(codePoint) === 'Lt';
};
var isWhiteSpace = function isWhiteSpace(codePoint) {
  var category = getCategory(codePoint);
  return category === 'Zs' || category === 'Zl' || category === 'Zp';
};
var isBaseForm = function isBaseForm(codePoint) {
  var category = getCategory(codePoint);
  return category === 'Nd' || category === 'No' || category === 'Nl' || category === 'Lu' || category === 'Ll' || category === 'Lt' || category === 'Lm' || category === 'Lo' || category === 'Me' || category === 'Mc';
};
var isMark = function isMark(codePoint) {
  var category = getCategory(codePoint);
  return category === 'Mn' || category === 'Me' || category === 'Mc';
};
var index = {
  getCategory: getCategory,
  getCombiningClass: getCombiningClass,
  getScript: getScript,
  getEastAsianWidth: getEastAsianWidth,
  getNumericValue: getNumericValue,
  isAlphabetic: isAlphabetic,
  isDigit: isDigit,
  isPunctuation: isPunctuation,
  isLowerCase: isLowerCase,
  isUpperCase: isUpperCase,
  isTitleCase: isTitleCase,
  isWhiteSpace: isWhiteSpace,
  isBaseForm: isBaseForm,
  isMark: isMark
};

export default index;
export { getCategory, getCombiningClass, getEastAsianWidth, getNumericValue, getScript, isAlphabetic, isBaseForm, isDigit, isLowerCase, isMark, isPunctuation, isTitleCase, isUpperCase, isWhiteSpace };
